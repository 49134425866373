/**
 * This store is used for managing Login, Authorization and User Information
 **/
/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import axios from "axios";
import { config, loginConfig } from "../utils/configsecurity";
import { TokenManager, UserManager } from "@forgerock/javascript-sdk";
import { generateChars } from "choices.js/public/assets/scripts/choices";
// import router from "../router/index.js";

// var https = require("https");
const _https = axios.create({
  //   httpsAgent: new https.Agent({
  //     rejectUnauthorized: false,
  //   }),
});

export const useUserSecurityStore = defineStore("usersecurity", {
  state: () => {
    return {
      status: "",
      credentialPolicy: {
        passwordRegex: null,
        pinLength: 0,
        key: null,
      },
      authcontext: {
        username: "",
        password: "",
      },
      ssoToken: null,
      tokens: undefined,
      userInfo: {},
      idInfo: undefined,
      isIntegrated: config.integrated,
    };
  },

  getters: {
    isLoggedIn: (state) => state.ssoToken,
    // isIntegrated: (state) => state.isIntegrated,
  },

  actions: {
    async public() {
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.PUBLIC,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: "{}",
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("public failed");
            reject(err);
          });
      });
    },

    async nonce(key) {
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.NONCE,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: {
            publickey: key,
          },
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("nonce failed");
            reject(err);
          });
      });
    },

    async login_url(payload) {
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.LOGIN_URL,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: {
            username: payload.user,
            password: payload.encrypttext,
          },
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("login_url failed");
            reject(err);
          });
      });
    },

    async authorize(renew) {
      if (renew) {
        console.log("== begin authorize new == ");
      }
      return await new Promise((resolve, reject) => {
        return TokenManager.getTokens({
          forceRenew: renew,
          login: "embedded",
          support: "legacy",
        })
          .then((tokens) => {
            if (tokens.accessToken) {
              return resolve(tokens);
            } else {
              // console.log("authorize  " + tokens);
              // return resolve(null);
              return window.location.assign("/");
            }
          })
          .catch((err) => {
            console.log("authorize failed " + err);
            reject(err);
            return window.location.assign("/");
          });
      });
    },

    async refresh(refresh) {
      console.log("== begin refresh new === ");
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.REFRESH,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: new URLSearchParams({
            grant_type: "refresh_token",
            refresh_token: refresh,
            client_id: "nvtx-boapp",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "POST",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("refresh failed");
            reject(err);
          });
      });
    },

    async generateOTP(payload) {
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.GENERATE_OTP,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: {
            userLevel: payload.userLevel,
            userUid: payload.userUid,
            transactionUid: payload.transactionUid,
          },
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("generateOTP failed");
            reject(err);
          });
      });
    },

    async validateOTP(payload) {
      return new Promise((resolve, reject) => {
        _https({
          url: loginConfig.AMSERVICE + loginConfig.VALIDATE_OTP,
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          data: {
            transactionUid: payload.transactionUid,
            otpPrefix: payload.otpPrefix,
            otp: payload.otp,
          },
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log("validateOTP failed");
            reject(err);
          });
      });
    },

    userInfoData() {
      let userInfo = UserManager.getCurrentUser();
      this.userInfo = userInfo;
      // console.log("userInfo : " + JSON.stringify(userInfo));
    },

    logout() {
      return new Promise((resolve) => {
        this.clearSession();
        resolve();
      });
    },

    clearSession() {
      TokenManager.deleteTokens();
      this.ssoToken = undefined;
      this.userInfo = {};
    },
  },
});
