import { Config } from "@forgerock/javascript-sdk";
const config = Object.freeze({
  // Server API Integration
  integrated: true,
});

const commonfx = {
  count: {
    tx: 0,
  },
  genTxId: function () {
    let now = new Date();
    let timestamp = now.getFullYear().toString(); // 2011
    timestamp +=
      (now.getMonth() < 9 ? "0" : "") + (now.getMonth() + 1).toString(); // JS months are 0-based, so +1 and pad with 0's
    timestamp += (now.getDate() < 10 ? "0" : "") + now.getDate().toString(); // pad with a 0
    timestamp += (now.getHours() < 10 ? "0" : "") + now.getHours().toString(); // pad with a 0
    timestamp +=
      (now.getMinutes() < 10 ? "0" : "") + now.getMinutes().toString(); // pad with a 0
    timestamp +=
      (now.getSeconds() < 10 ? "0" : "") + now.getSeconds().toString(); // pad with a 0
    timestamp += "-" + now.getMilliseconds().toString();
    return timestamp + "_" + this.count.tx++;
  },
};

//Authorize Configure DEV
// Config.set({
//   clientId: "nvtx-boapp",
//   clientSecret: "nvtx@app01",
//   redirectUri: `${window.location.origin}/callback`,
//   realmPath: "bdmn",
//   scope: "profile openid acm_rights",
//   tokenStore: "localStorage",
//   logLevel: "debug",
//   serverConfig: {
//     baseUrl: "https://dev-sso.nvtx.tech",
//     timeout: 30000,
//     paths: {
//       authorize: "/sec-svc/authz/authorize",
//       accessToken: "/sec-svc/authz/access_token",
//     },
//   },
// });

// Authorize Configure UAT
// Config.set({
//   clientId: "nvtx-boapp",
//   clientSecret: "BKTP@ssw0rd",
//   redirectUri: `${window.location.origin}/callback`,
//   scope: "profile acm_rights",
//   tokenStore: "localStorage",
//   logLevel: "debug",
//   serverConfig: {
//     baseUrl: "https://uat-sso.nvtx.tech",
//     timeout: 30000,
//     paths: {
//       authorize: "/sec-svc/authz/authorize",
//       accessToken: "/sec-svc/authz/access_token",
//     },
//   },
// });

// Authorize Configure PROD
Config.set({
  clientId: "nvtx-boapp",
  redirectUri: `${window.location.origin}/callback`,
  scope: "profile acm_rights",
  tokenStore: "localStorage",
  logLevel: "debug",
  serverConfig: {
    baseUrl: "https://sso.nvtx.tech",
    timeout: 30000,
    paths: {
      authorize: "/sec-svc/authz/authorize",
      accessToken: "/sec-svc/authz/access_token",
    },
  },
});

//Login Configure
const loginConfig = {
  // AMSERVICE: "https://dev-sso.nvtx.tech",
  // AMSERVICE: "https://uat-sso.nvtx.tech",
  AMSERVICE: "https://sso.nvtx.tech",
  LOGIN_URL: "/sec-svc/authn/user/login",
  CREDENTIAL_POLICY: "/iam/customer/onboard/credential",
  NONCE: "/sec-svc/authn/user/nonce",
  PUBLIC: "/sec-svc/authn/user/public",
  LOGOUT: "/sec-svc/authn/user/logout",
  REFRESH: "/sec-svc/authz/access_token",
  AUTHORIZE: "/sec-svc/authz/authorize",
  ACCESSTOKEN: "/sec-svc/authz/access_token",
  GENERATE_OTP: "/sec-svc/authn/otp/generate",
  VALIDATE_OTP: "/sec-svc/authn/otp/validate",
};

export { config, commonfx, loginConfig };
