const messages = {
  th: {
    langs: {
      tha: "ไทย",
      eng: "English",
    },
    apps: {
      name: "บัดดี้ มันนี่",
      master: "ข้อมูลพื้นฐาน",
      general: "ทั่วไป",
      setting: "การตั้งค่า",
      gdashboard: "แดชบอร์ด",
      gtransaction: "ธุรกรรม",
      gmanage: "จัดการธุรกรรมตั๋ว",
      gticket: "รวมตั๋ว/แยกตั๋ว",
      gfinance: "การชำระ",
      gsplitasset: "แยกย่อยทรัพย์",
      ghistory: "ประวัติธุรกรรม",
      gticketchain: "ประวัติธุรกรรมตั๋ว",
      gcashmove: "เส้นทางการเงิน",
      gtrans: "จัดการเงิน",
      gdeposit: "ฝากเงินลูกค้า",
      gcustomer: "ข้อมูลลูกค้า",
      gport: "พอร์ตลูกค้า",
      report: "รายงาน",
      gunitover: "ทรัพย์หลุด",
      gdeferitems: "ของผลัด",
      mbank: "ธนาคาร",
      mwarning: "บุคคลเฝ้าระวัง",
      millegal: "ทรัพย์โจร",
      mtypeassets: "ข้อมูลทรัพย์",
      mprefix: "คำนำหน้าชื่อ",
      maddress: "ข้อมูลที่อยู่",
      mholiday: "วันหยุด",
      mtypecustomer: "ประเภทลูกค้า",
      mtypedocument: "ประเภทเอกสาร",
      mtypeunit: "หน่วยสินค้า",
      mpawn: "ธนาคารโรงรับจำนำ",
      mcash: "บัญชีเงินสด",
      mbanknote: "ประเภทธนบัตร",
      mbiz: "ประเภทธุรกรรม",
      sopen: "เปิดสาขาใหม่",
      ssetbranch: "กำหนดค่าสาขา",
      spre: "Pre-print & Running",
      sinterest: "ดอกเบี้ย",
      sstock: "คลัง",
      sgold: "ราคาทอง",
      srole: "สิทธิ์ผู้ใช้งาน",
      ssetup: "ตั้งค่าสิทธิ์",
      suser: "ผู้ใช้งาน",
      acprofile: "โปรไฟล์",
      acsetting: "ตั้งค่า",
      aclogout: "ออกจากระบบ",
      massetgroup: "ประเภททรัพย์",
      financial: "การเงิน",
      cashdrawer: "เงินสดหน้างาน",
      customer: "ลูกค้า",
      seize: "ตั๋วหาย & อายัด",
      location: "สถานที่เก็บ",
      stock: "สต็อกโรงรับจำนำ",
      reportday: "สรุปธุรกรรมประจำวัน",
      reportall: "สรุปการดำเนินการ",
      reportpawn: "รายงานธุรกรรมจำนำ",
      reportprofit: "รายงานธุรกรรมต่อดอก",
      reportaddcap: "รายงานธุรกรรมเพิ่มต้น",
      reportreducecap: "รายงานธุรกรรมลดต้น",
      reportredeem: "รายงานธุรกรรมไถ่ถอน",
      reportcancel: "รายงานการยกเลิกธุรกรรม",
      reportdefer: "รายงานตั๋วใกล้ครบกำหนด-ผลัด",
      reporttop: "รายงาน Top 20",
      reportpay: "รายงานรับ-จ่ายชำระ",
      exportitemoverdue: "Export ข้อมูลทรัพย์หลุด",
      stocks: "สต็อก",
      checktxn: "ตรวจสอบธุรกรรม",
      verifypayment: "ตรวจสอบการชำระ",
      goverall: "ภาพรวมการชำระเงิน",
      verifypayment2: "ตรวจสอบการชำระ2",
      version: "เวอร์ชั่น",
      amnreporterrorticket: "รายงานธุรกรรมตั๋วเสีย",
      closecashdrawer: "ปิดเงินสดหน้างาน",
      reconcile: "กระทบยอดบัญชี",
      closebankdrawer: "ปิดยอดเงินธนาคาร",
    },
    heads: {
      men: "เมนู",
      clo: "ปิด",
      hom: "หน้าหลัก",
      new: "ข่าว/โปรโมชั่น",
      pro: "สินค้าและบริการ",
      abo: "เกี่ยวกับเรา",
      con: "ติดต่อเรา",
      ope: "เปิดบัญชี",
      log: "เข้าสู่ระบบ",
      por: "พอร์ต",
      his: "ประวัติ",
      res: "จอง",
      cle: "เคลียร์พอร์ต",
      not: "แจ้งเตือน",
      acc: "ตั้งค่าบัญชี",
      out: "ออกจากระบบ",
      rem: "จดจำบัญชี",
      usr: "ชื่อผู้ใช้งาน",
      pwd: "รหัสผ่าน",
      ema: "อีเมล",
      mob: "มือถือ",
      adr: "ที่อยู่",
      fgpwd: "ลืมรหัสผ่าน",
      rspwd: "รีเซ็ตรหัสผ่าน",
      rspwdlb: "เลือกช่องทางเพื่อรับรหัส OTP",
    },
    buttons: {
      okay: "ตกลง",
      back: "กลับ",
      prev: "ย้อนกลับ",
      next: "ต่อไป",
      canc: "ยกเลิก",
      conf: "ยืนยัน",
      save: "บันทึก",
      acce: "ยอมรับ",
      decl: "ปฏิเสธ",
      open: "เปิด",
      clos: "ปิด",
      clea: "ล้าง",
      dele: "ลบ",
      sele: "เลือก",
      comp: "เสร็จสิ้น",
      uplo: "อัพโหลด",
      send: "ส่ง",
      sear: "ค้นหา",
      addd: "เพิ่ม",
      show: "แสดง",
      hide: "ซ่อน",
      remo: "ลบ",
      chan: "เปลี่ยน",
      sett: "ตั้งค่า",
      impo: "เพิ่มเข้า",
      edit: "แก้ไข",
      file: "เลือกไฟล์",
      split: "แยกรายการ",
      seed: "ดูรายละเอียด",
      print: "พิมพ์เอกสาร",
      sscan: "เริ่มสแกน",
      nscan: "สแกนใหม่",
      load: "กำลังโหลด ...",
      apprv: "อนุมัติ",
      rejec: "ปฏิเสธ",
      opencam: "เปิดกล้อง",
      closecam: "ปิดกล้อง",
      takePhoto: "ถ่ายภาพ",
      preview: "ตัวอย่างภาพ",
      retakePhoto: "ถ่ายภาพใหม่",
      canct: "ยกเลิกตั๋ว",
      printp: "พิมพ์ตั๋ว",
      printpb: "พิมพ์ใบปิดหลังตั๋ว",
      savePrint: "บันทึกและพิมพ์",
    },
    content: {
      gtotal: "รวม",
      act: "จัดการ",
      en: "อังกฤษ",
      th: "ไทย",
      mbank: "ธนาคาร",
      mbankname: "ชื่อธนาคาร",
      mlogo: "โลโก้",
      mbankpshort: "ชื่อย่อ",
      mbankp: "ธนาคารโรงรับจำนำ",
      mcolor: "สี",
      note: "หมายเหตุ",
      mcash: "รายชื่อบัญชีเงินสด",
      mcashname: "ชื่อบัญชีเงินสด",
      mbanknote: "ประเภทธนบัตร",
      mbanknotetype: "ประเภทเงิน",
      mbanknotevalue: "ตัวคูณ",
      maddress: "ข้อมูลที่อยู่",
      detail: "รายละเอียด",
      date: "วันที่",
      mholiday: "วันหยุด",
      munit: "หน่วยสินค้า",
      massetgroup: "ประเภททรัพย์",
      mtypedoc: "ประเภทเอกสาร",
      add1: "ประเทศ",
      add2: "จังหวัด",
      add3: "เขต/อำเภอ",
      add4: "แขวง/ตำบล",
      add5: "รหัสไปรษณีย์",
      gmoney: "จัดการเงิน",
      history: "ประวัติ",
      gmoneybiz: "จัดการธุรกรรม",
      sdate: "วันเริ่มต้น",
      edate: "วันสิ้นสุด",
      refno: "เลขอ้างอิง",
      trans: "ประเภทธุรกรรม",
      dant: "วันที่/เวลา",
      file: "ไฟล์แนบ",
      amount: "จำนวนเงิน",
      gmoneyhis: "ประวัติจัดการเงิน",
      type: "ประเภท",
      glisttrans: "รายการธุรกรรม",
      gmoneytype: "ประเภทเงิน",
      gdepohis: "ประวัติฝากเงินลูกค้า",
      gdepoman: "จัดการฝากเงินลูกค้า",
      names: "ชื่อ-นามสกุล",
      cnamelist: "รายชื่อลูกค้า",
      cname: "ชื่อลูกค้า",
      ccode: "รหัสลูกค้า",
      cnameth: "ชื่อ-นามสกุล(ไทย)",
      cnameen: "ชื่อ-นามสกุล(อังกฤษ)",
      cidcard: "เลขที่บัตรประชาชน",
      cmobile: "เบอร์มือถือ",
      cgrade: "ประเภทลูกค้า",
      optall: "ทั้งหมด",
      gcashmove: "เส้นทางการเงิน",
      customer: "ลูกค้า",
      gcashmovetype: "ประเภท Movement",
      item: "รายการ",
      depo: "ฝาก",
      withd: "ถอน",
      total: "คงเหลือ",
      expfile: "ส่งออกไฟล์",
      millegal: "ทรัพย์โจร",
      assetinfo: "ข้อมูลทรัพย์",
      serial: "หมายเลขรุ่น",
      refer: "แหล่งที่มา",
      docno: "เลขบัตรประชาชน/เลขที่เอกสารอื่นๆ",
      docdate: "วันที่เอกสาร",
      criminal: "คดีอาญา",
      agency: "จากหน่วยงาน",
      defect: "ตำหนิ/ลักษณะพิเศษ",
      mwarning: "บุคคลเฝ้าระวัง",
      doctype: "ประเภทเอกสาร",
      cardno: "เลขที่บัตร",
      warnlvl: "ระดับความอันตราย",
      select: "กรุณาเลือก",
      ssetbranch: "กำหนดค่าสาขา",
      status: "สถานะ",
      activity: "หัวข้อ",
      setallopen: "(เปิด-ปิด) ระบบทั้งหมด",
      detailallopen:
        "เปิด (เปิดระบบทั้งหมด), ปิด (ปิดระบบทั้งหมด), ใช้ได้เฉพาะผู้ดูแลระบบ",
      setfixbackend: "วันและเวลา (เปิด-ปิด) ระบบหลังบ้าน",
      detailfixbackend:
        "เปิด (ระบุวันและเวลาเปิด-ปิด), ปิด (ปิดระบบทุกวัน), จะถูกวันหยุดซ้อนทับทุกกรณี และถ้าไม่ตั้งค่าวันเวลาเปิด-ปิดระบบ จะเป็นแบบเปิดตลอด 24 ชม./ 7 วัน",
      day: "วัน",
      onoff: "เปิด-ปิด",
      opentime: "เวลาเปิด",
      to: "ถึง",
      closetime: "เวลาปิด",
      sun: "วันอาทิตย์",
      mon: "วันจันทร์",
      tue: "วันอังคาร",
      wed: "วันพุธ",
      thu: "วันพฤหัสบดี",
      fri: "วันศุกร์",
      sat: "วันเสาร์",
      close: "ปิด",
      open: "เปิด",
      gticketchain: "ประวัติธุรกรรมตั๋ว",
      ticketno: "เลขที่ตั๋ว",
      allweight: "น้ำหนักรวม",
      allqty: "จำนวนรวม",
      allprice: "ราคารวม",
      gcustomer: "ข้อมูลลูกค้า",
      gcustomerman: "จัดการข้อมูลลูกค้า",
      cstatus: "สถานะลูกค้า",
      activate: "วันที่เปิดบัญชี",
      lastact: "วันที่ธุรกรรมล่าสุด",
      resetpwd: "รีเซ็ตรหัสผ่าน",
      cardtype: "ประเภทบัตร",
      pcissue: "สถานที่ออกบัตร",
      cdissue: "วันออกบัตร",
      cdexpire: "วันหมดอายุบัตร",
      cprefix: "คำนำหน้าชื่อ",
      cnam: "ชื่อ",
      clsnam: "นามสกุล",
      cbirth: "วัน/เดือน/ปีเกิด",
      cage: "อายุ",
      crelig: "ศาสนา",
      cblood: "หมู่เลือด",
      cnation: "สัญชาติ",
      cstage: "สถานะลูกค้า",
      caddress: "ที่อยู่",
      cstate: "จังหวัด",
      csprov: "เขต/อำเภอ",
      cscity: "แขวง/ตำบล",
      cemail: "อีเมล",
      cupload: "เลือกภาพ",
      cbankinfo: "ข้อมูลบัญชีธนาคาร",
      cbankname: "ธนาคาร",
      cbankno: "เลขที่บัญชีธนาคาร",
      cbankacc: "ชื่อบัญชีธนาคาร",
      cselectuse: "ใช้งาน",
      cdefault: "ค่าเริ่มต้น",
      cintrate: "ดอกเบี้ยต่อเดือน",
      cintrate1: "ปกติ",
      cintrate2: "พิเศษ",
      csign: "ลายเซ็นต์",
      cfprint: "ลายพิมพ์นิ้วมือ",
      cabove: "บุคคลเกี่ยวข้อง",
      crlname: "ค้นหา ชื่อ-นามสกุล",
      gdashboard: "แดชบอร์ด",
      byday: "รายวัน",
      byweek: "รายอาทิตย์",
      bymonth: "รายเดือน",
      gport: "พอร์ตลูกค้า",
      grelation: "พอร์ตบุคคลที่เกี่ยวข้อง",
      allassets: "สินทรัพย์ทั้งหมด",
      holdon: "ฝากตั๋ว",
      totunit: "จำนวนทั้งหมด",
      totprice: "ราคารวม",
      totvalue: "รวมมูลค่า",
      totmaxval: "มูลค่าทรัพย์ทั้งหมด",
      totlimit: "มูลค่าจำนำทั้งหมด",
      personhis: "ประวัติธุรกรรมส่วนบุคคล",
      totdeposit: "เงินที่ฝากไว้",
      holding: "ฝากไว้",
      nothold: "ไม่ได้ฝากไว้",
      statusticket: "สถานะตั๋ว",
      curency: "บาท",
      time: "เวลา",
      actpawn1: "จำนำ",
      actpawn2: "ต่อดอก",
      actpawn3: "เพิ่มต้น",
      actpawn4: "ลดต้น",
      actpawn5: "ไถ่ถอน",
      actpawn6: "แยกไถ่",
      amnenter: "จำนวนลูกค้าที่เข้ามาทำธุรกรรม(คน)",
      amnqty: "จำนวนธุรกรรมทั้งหมด(ครั้ง)",
      amnmoney: "จำนวนธุรกรรมทั้งหมด(บาท)",
      amndue: "สรุปตั๋วถึงกำหนดชำระ",
      amnticketdueactived: "ตั๋วถึงกำหนดที่เคลียร์แล้วในวันนี้(ใบ)",
      amnticketdued: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในวันนี้(ใบ)",
      amnticketoveractived: "ตั๋วเลยกำหนดแล้วเมื่อวานนี้(ใบ)",
      amnticketoverd: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในวันนี้(ใบ)",
      amnticketdueactivew: "ตั๋วถึงกำหนดที่เคลียร์แล้วในอาทิตย์นี้(ใบ)",
      amnticketduew: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในอาทิตย์นี้(ใบ)",
      amnticketoveractivew: "ตั๋วเลยกำหนดแล้วอาทิตย์นี้(ใบ)",
      amnticketoverw: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในอาทิตย์นี้(ใบ)",
      amnticketdueactivem: "ตั๋วถึงกำหนดที่เคลียร์แล้วในเดือนนี้(ใบ)",
      amnticketduem: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในเดือนนี้(ใบ)",
      amnticketoveractivem: "ตั๋วเลยกำหนดแล้วเดือนนี้(ใบ)",
      amnticketoverm: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในเดือนนี้(ใบ)",
      amncash: "เงินสด",
      amnbankacc: "บัญชีธนาคาร",
      amntotbaht: "รวมบาท",
      amndrawer: "ลิ้นชักเงินสด",
      amnlistcash: "รายการเงินสด",
      amnlistremain: "รายการคงเหลือ",
      amnremain: "คงเหลือ",
      amnpay: "จ่ายเงิน",
      amnget: "รับเงิน",
      amntransfer: "โอนเงินเลขที่บัญชี",
      amntotaltransact: "สรุปยอด",
      amnduedate: "วันครบกำหนด",
      amnduedatenew: "วันครบกำหนด(ใหม่)",
      amnduedateadd: "จำนวนวันที่เพิ่ม",
      amninterest: "ดอกเบี้ย",
      amnticketduelist: "รายการครบกำหนดตั๋ว",
      amnsafe: "เงินสดในเซฟ",
      amntypecustomer: "ประเภทลูกค้า",
      amncredit: "วงเงิน",
      amnchannel: "ช่องทาง",
      amntimestart: "เวลาเริ่มต้น",
      amntimeend: "เวลาสิ้นสุด",
      amncashflow: "รายรับ-รายจ่าย",
      amnincome: "เงินเข้า",
      amnoutcome: "เงินออก",
      amnprofit: "กำไร",
      amndeposit: "ฝากเงิน",
      amnwithdraw: "ถอนเงิน",
      amnpawnlist: "รายการทรัพย์จำนำ",
      amnsplitlist: "รายการแยกทรัพย์",
      amnassetowner: "เจ้าของทรัพย์",
      amncreditget: "วงเงินที่ได้รับ",
      amncredituse: "วงเงินที่ใช้ไป",
      amncreditremain: "วงเงินคงเหลือ",
      amnassetdetail: "รายละเอียดทรัพย์",
      amnassetdatas: "ข้อมูลรายการทรัพย์จำนำตามหน้าตั๋ว",
      amnweightval: "น้ำหนัก(กรัม)",
      amnweightvalall: "น้ำหนักรวม(กรัม)",
      amnanalysval: "ราคาจำนำ",
      amnanalysvalall: "ราคาจำนำ(รวม)",
      amnmaxval: "มูลค่าเต็ม",
      amnmaxvalall: "มูลค่าเต็มรวม",
      amnqtydiamond: "จำนวน(เม็ด)",
      amnqtyunit: "จำนวน(ชิ้น)",
      amnqtyunitall: "จำนวนรวม(ชิ้น)",
      amnfingerprint: "พิมพ์ลายนิ้วมือ",
      amngold: "ทอง",
      amndiamond: "เพชร",
      amnframe: "กรอบพระ",
      amncopper: "นาค",
      amnwatch: "นาฬิกา",
      amnothers: "อื่นๆ",
      amnamulet: "พระ",
      amnsilver: "เงิน",
      amngem: "พลอย",
      amntypeassets: "ข้อมูลทรัพย์",
      amnstateacting: "กำลังดำเนินการ",
      amnstateending: "พร้อมชำระ",
      amnsearchticker: "ค้นหาตั๋ว",
      amndatetime: "วัน/เวลา",
      amnassetgroup: "ประเภททรัพย์",
      amnbiz: "ประเภทธุรกรรม",
      amnoldmessage: "รายละเอียดทรัพย์เดิม",
      amngroupticket: "กลุ่มตั๋ว",
      amnticketlist: "รายการตั๋ว",
      amnticketmanage: "จัดการตั๋ว",
      amnseedetail: "ดูรายละเอียด",
      amnsplititem: "แยกรายการ",
      amnscancelitem: "ยกเลิก",
      amnonprocess: "รอดำเนินการ",
      amncancelticket: "ยกเลิกธุรกรรมตั๋ว",
      amnbankprocess: "สถานะยืนยันชำระ",
      amncanceleditnew: "ยกเลิกเพื่อแก้ไขตั๋วใหม่",
      amnfinanceno: "เลขที่ชำระ",
      amnfinancestatus: "สถานะชำระ",
      amnfinanceok: "สมบูรณ์",
      amnfinancenot: "ยังไม่ชำระ",
      amnfinancepartial: "ชำระบางส่วน",
      amnfinanceremain: "จำนวนเงินคงเหลือ",
      amnfinancedoneall: "ยอดชำระรวม",
      amnfinancesearch: "ค้นหารายการชำระ",
      amnfinancelist: "รายการชำระ",
      amnfinancenmanage: "จัดการชำระ",
      amnbankinfo: "ข้อมูลธนาคาร",
      amnselectlist: "เลือกรายการ",
      amnbankaccno: "เลขที่บัญชี",
      amnbankactive: "บัญชีใช้งาน",
      amnfinanceinput: "ยอดรับ",
      amnfinanceoutput: "ยอดจ่าย",
      amnfinancetype: "สถานะการชำระ",
      amnfinancetotal: "รวมชำระ",
      amnfinanceinfo: "ข้อมูลรายการชำระ",
      amnfinanceprocess: "ดำเนินการชำระ",
      amncreditcard: "บัตรเครดิต",
      amncheque: "เช็ค",
      amntypetrans: "ประเภทชำระ",
      amnhowtomoney: "เช็ค/เลขบัตรเครดิต/เลขอ้างอิง",
      amninputmoneytype: "จำนวนเงินที่จ่าย/ที่รับ",
      amnsourcemoney: "แหล่งเงิน",
      amnqrrunapp: "สแกน QR code เพื่อทำงานต่อบน app",
      amnfromdrawer: "จากลิ้นชัก",
      amnfrombankacc: "จากบัญชี",
      amnfinancenote: "หมายเหตุการชำระ",
      amnpayremain: "ค้างจ่าย",
      amnreceiveremain: "ค้างรับ",
      amnrelatenum: "จำนวนบุคคลเกี่ยวข้อง",
      amnaddnew: "เพิ่มรายการ",
      amndeferitems: "ของผลัด",
      amnuser: "ผู้ใช้งาน",
      amnusermanage: "จัดการผู้ใช้งาน",
      amnusername: "ชื่อผู้ใช้งาน",
      amnusernick: "ชื่อเล่น",
      amnadmin: "ผู้ดูแลระบบ",
      amnuserrole: "ประเภทผู้ใช้งาน",
      amnuserright: "สิทธิ์ผู้ใช้งาน",
      amneffectdate: "วันที่มีผล",
      amnenddate: "วันหมดอายุ",
      amnsplitasset: "แยกย่อยทรัพย์",
      amnsplitassetman: "จัดการแยกย่อยทรัพย์",
      amntypegold: "ทรัพย์เป็นทอง",
      amnnotgold: "ทรัพย์ที่ไม่ใช่ทอง",
      amndefertime: "จำนวนที่เคยผลัด",
      amnrecheckfinance: "ตรวจสอบข้อมูล",
      amnconfirmfinance: "ยืนยันข้อมูล",
      amnamountall: "จำนวนเงินทั้งหมด",
      amnbillno: "เลขที่บิล",
      amnbilldate: "วันที่ออกบิล",
      amndillinfo: "ข้อมูลบิล",
      amnrecheckdone: "ตรวจสอบแล้ว",
      amnreturn: "ตีกลับ",
      amnotpcode: "รหัส OTP",
      amnotpref: "เลขอ้างอิงรหัส OTP",
      amnotptime: "กรุณากรอกรหัส OTP ภายใน",
      amnmin: " นาที",
      amnfinancial: "การเงิน",
      amncashdrawer: "เงินสดหน้างาน",
      amnseize: "ตั๋วหาย & อายัด",
      amnlocation: "สถานที่เก็บ",
      amnstock: "สต็อกโรงรับจำนำ",
      amnreportall: "สรุปการดำเนินการ",
      amnreportday: "สรุปธุรกรรมประจำวัน",
      amnreportpawn: "รายงานธุรกรรมจำนำ",
      amnreportprofit: "รายงานธุรกรรมต่อดอก",
      amnreportaddcap: "รายงานธุรกรรมเพิ่มต้น",
      amnreportreducecap: "รายงานธุรกรรมลดต้น",
      amnreportredeem: "รายงานธุรกรรมไถ่ถอน",
      amnreportcancel: "รายงานการยกเลิกธุรกรรม",
      amnreportdefer: "รายงานตั๋วใกล้ครบกำหนด-ผลัด",
      amnreporttop: "รายงาน Top 20",
      amnreportpay: "รายงานรับ-จ่ายชำระ",
      amnexportitemoverdue: "Export ข้อมูลทรัพย์หลุด",
      amnstocks: "สต็อก",
      amncashdrawerman: "จัดการเงินสดหน้างาน",
      amncashdrawername: "ชื่อบัญชีเงินสด",
      amncashdrawerbalance: "ขาด/เกินระหว่างวัน",
      amncashdrawerdiff: "ส่วนต่าง",
      amnmoneylimit: "วงเงินสุงสุด",
      amnvalue: "มูลค่า",
      amnticketlost: "ตั๋วหาย",
      amnseizeact: "อายัดตั๋ว",
      amnticketlostman: "จัดการตั๋วหาย",
      amnseizeactman: "จัดการอายัดตั๋ว",
      amnpastsearch: "ค้นหาย้อนหลัง",
      amndantinfo: "วันที่/เวลาที่แจ้ง",
      amnbizinfo: "ประเภทธุรกรรมที่แจ้ง",
      amncurrent: "สถานะปัจจุบัน",
      amnmaker: "ผู้ทำรายการ",
      amnchecker: "ผู้ตรวจรายการ",
      amnwaitreport: "รอแจ้งความ",
      amnfinreport: "แจ้งความเรียบร้อยแล้ว",
      amnsuspend: "อายัดถาวร",
      amnsuspend2: "อายัดอย่างเป็นทางการ",
      amnbanned: "อายัดชั่วคราว",
      amninformlost: "แจ้งหาย",
      amnunlock: "ปลดล็อก",
      amnreason: "เหตุผล",
      amnseizetype: "ประเภทอายัด",
      amninformseize: "แจ้งอายัด",
      amnunitover: "ทรัพย์หลุด",
      amnpawndate: "วันที่จำนำ",
      amnstatusdefer: "ผลัด",
      amnstatusover: "หลุด",
      amnqtyitem: "จำนวน",
      amnpawnval: "มูลค่าจำนำ",
      amnprofitval: "มูลค่าดอกเบี้ย",
      amnoverduedays: "จำนวนวันที่เกินกำหนด",
      amnperiodtime: "ระยะเวลา",
      amnduewithin: "ใกล้ถึงกำหนดภายใน",
      amnlevelno: "ลำดับ",
      amnlresult: "สรุป",
      amnassetstatus: "สถานะทรัพย์",
      amntotalpawnval: "ราคาจำนำรวม",
      amntotalpawnmax: "ราคารับจำนำสูงสุด",
      amntotalpawnmin: "ราคารับจำนำต่ำสุด",
      amntotalpawnavg: "ราคารับจำนำเฉลี่ย",
      amndaytotalpawnmax: "ระยะเวลาที่จำนำสูงสุด",
      amndaytotalpawnmin: "ระยะเวลาที่จำนำต่ำสุด",
      amndaytotalpawnavg: "ระยะเวลาที่จำนำเฉลี่ย",
      amnassetimage: "รูปทรัพย์",
      amndaytotalpawn: "ระยะเวลาที่จำนำ",
      amntimes: "จำนวนครั้ง",
      amntotalval: "มูลค่ารวม",
      amntotalvalmax: "มูลค่าสูงสุด",
      amntotalvalmin: "มูลค่าต่ำสุด",
      amntotalvalavg: "มูลค่าเฉลี่ย",
      amntotalavgval: "มูลค่ารวมเฉลี่ย",
      amnticketold: "เลขที่ตั๋วเดิม",
      amnticketnew: "เลขที่ตั๋วใหม่",
      amntypepawn: "ประเภทจำนำ",
      amnnewaction: "ธุรกรรมใหม่",
      amnoldaction: "ธุรกรรมเดิม",
      amnpawnvalall: "มูลค่าจำนำรวม",
      amnevaluate: "มูลค่าประเมิน",
      amndifference: "ส่วนต่างมูลค่า",
      amnreceiveprofit: "ดอกเบี้ยรับ",
      amndatepayprofit: "วันที่จ่ายดอกเบี้ย",
      amnreceiptno: "เลขที่ใบเสร็จ",
      amndiscount: "ส่วนลด",
      amnexceptprofit: "ยกเว้นดอกเบี้ย",
      amnrealprofit: "ดอกเบี้ยรับจริง",
      amnpaytimeprofit: "จำนวนครั้งที่ส่งดอกเบี้ย",
      amnvaluecompare: "มูลค่าดอกเบี้ยทั้งหมดเทียบเงินต้น(%)",
      amnaddcapdate: "วันที่เพิ่มต้น",
      amnreducecapdate: "วันที่ลดต้น",
      amnevalueadd: "มูลค่าเพิ่มต้น",
      amnevaluereduce: "มูลค่าลดต้น",
      amnnewtotalprofit: "ยอดดอกเบี้ยใหม่",
      amndifferencepawn: "ส่วนต่างมูลค่าจำนำ",
      amndifferenceprofit: "ส่วนต่างดอกเบี้ย",
      amnallredeem: "ไถ่ถอนทั้งหมด",
      amnredeemtype: "ประเภทไถ่ถอน",
      amnredeemdate: "วันที่ไถ่ถอน",
      amntotalprofit: "ยอดดอกเบี้ย",
      amnevalueredeem: "มูลค่าไถ่ถอน",
      amndifferenceredeem: "ส่วนต่างมูลค่าไถ่ถอน",
      amnticketcancelno: "เลขที่ตั๋วที่ยกเลิก",
      amnbookno: "เล่มที่",
      amnno: "เลขที่",
      amnrealoutput: "ยอดจ่ายจริง",
      amndeferdate: "วันที่ผลัด",
      amnfinreceive: "รับชำระ",
      amnfinpay: "จ่ายชำระ",
      amnbanktransfer: "เงินโอน",
      amnfinancetypes: "ประเภทธุรกรรมการเงิน",
      amnclearingdate: "วันที่ชำระ",
      amnclearingamount: "เงินที่ชำระ",
      amnclearingremain: "ค้างชำระ",
      amncustomerbankacc: "บัญชีลูกค้า",
      amntotalinput: "ยอดรับรวม",
      amntotaloutput: "ยอดจ่ายรวม",
      amngrandtotal: "รวมทั้งหมด",
      amnindex1: "20 อันดับแรกของลูกค้าที่มีจำนวนตั๋วมากที่สุด",
      amnindex2: "20 อันดับแรกของลูกค้าที่มีมูลค่าจำนำสูงที่สุด",
      amnindex3: "20 อันดับแรกของลูกค้าที่หลุดจำนำมากที่สุด",
      amnindex4: "20 อันดับแรกของทรัพย์ที่มีมูลค่าสูงที่สุด",
      amnindex5: "20 อันดับแรกของมูลค่าตั๋วสูงสุด",
      amnindex6: "20 อันดับแรกของลูกค้าที่มีการจำนำและไถ่ถอนมากที่สุด",
      amnindex7: "20 อันดับแรกของของการส่งดอกเบี้ยเทียบเงินต้น(%)",
      amnanalysvalold: "ราคาจำนำเก่า",
      amnanalysvalallold: "ราคาจำนำ(รวม)เก่า",
      amnanalysvalallnew: "ราคาจำนำ(รวม)ใหม่",
      amnfingerscan: "พิมพ์ลายนิ้วมือ",
      amnstartscan: "กด`เริ่มสแกน`เพื่อสแกนนิ้ว",
      amnmatchscan: "การจับคู่ลายนิ้วมือสำเร็จ",
      amnnotmatchscan: "ไม่พบลายนิ้วมือ",
      amnpaycompleted: "ชำระรายการสำเร็จ",
      amnallticket: "จำนวนตั๋วทั้งหมด",
      amnhdweightrest: "น้ำหนักรวมคงเหลือที่ใช้ได้(กรัม)",
      amnhdweightall: "น้ำหนักรวม",
      amnhdanalysrest: "ราคาจำนำคงเหลือที่ใช้ได้",
      amnhdanalysall: "ราคาจำนำรวม",
      amnhdmaxrest: "มูลค่าเต็มคงเหลือที่ใช้ได้",
      amnhdmaxall: "มูลค่าเต็มคงเหลือ",
      amnhdanalysold: "ราคาจำนำรวม(เก่า)",
      amnhdanalysnew: "ราคาจำนำรวม(ใหม่)",
      amnhdvalticket: "รวมมูลค่าตั๋ว",
      amnhdacreturnticket: "ตีกลับ",
      amnhdcfreturnticket: "ตั๋วถูกตีกลับไปแล้ว",
      amnmaintaindate: "คงเหลือ(วัน)",
      transc: "ประเภทการเงิน",
      ctranstin: "ภายในโรงรับจำนำ",
      ctranstout: "ภายนอกโรงรับจำนำ",
      ctranstexp: "ค่าใช้จ่าย",
      ctranstacc: "ปิดบัญชี",
      transt: "ประเภทธุรกรรม",
      ctranstca: "โยกเงินระหว่างบัญชีเงินสดหน้างาน",
      ctranstbc: "โยกเงินระหว่างบัญชีธนาคารและบัญชีเงินสด",
      ctranstii: "โยกเงินระหว่างบัญชีธนาคาร (ภายใน)",
      ctranstoi: "โยกเงินระหว่างบัญชีธนาคาร (ภายนอก)",
      ctranstge: "ค่าใช้จ่ายทั่วไป",
      ctranstcs: "เงินขาด",
      ctranstco: "เงินเกิน",
      ctranstuc: "เงินรอบันทึก",
      ctranstde: "ฝากเงิน",
      ctranstwi: "ถอนเงิน",
      ctransttr: "โอนเงิน",
      amntkbefo: "เลขที่ตั๋วก่อนหน้า",
      amntknew: "เลขที่ตั๋วใหม่",
      amncrcard: "บัตรเครดิต",
      amncollec: "เก็บทรัพย์",
      amnpendin: "รอดำเนินการ",
      amnticketoff: "ธุรกรรมตั๋วเสีย",
      amnticketedit: "แก้ไขข้อมูลตั๋ว",
      amngroupticketsearch: "ค้นหากลุ่มตั๋ว",
      amnalljobs: "งานทั้งหมด",
      amnlastmaker: "ผู้ทำรายการล่าสุด",
      amnchecktxn: "ตรวจสอบธุรกรรม",
      amnconfirmtxn: "ยืนยันธุรกรรม",
      amndetailtxn: "รายละเอียดธุรกรรม",
      amntakephoto: "ถ่ายภาพ",
      amnretakephoto: "ถ่ายภาพใหม่",
      amnnewjob: "สร้างงาน",
      amnjobcode: "รหัสงาน",
      amnselectauto: "เลือกตั๋วอัตโนมัติ",
      amnasgroup: "ตามกลุ่มชำระ",
      amnastype: "ตามประเภทธุรกรรม",
      amntxnoverall: "ภาพรวมการชำระเงิน",
      amnendjob: "จบการทำงาน",
      cexintra: "บันทึกเพิ่มเติม",
      cchacount: "จำนวนตัวอักษร",
      cenanoti: "เปิดรับการแจ้งเตือน",
      payside: "การชำระ",
      gprice: "รวม/ยกเลิกรวม ทรัพย์จำนำ",
      gweight: "รวม/ยกเลิกรวม น้ำหนัก",
      amnhdweightalltick: "น้ำหนักรวมทั้งตั๋ว(กรัม)",
      amnhdanalysalltick: "ราคาจำนำรวมทั้งตั๋ว",
      amnhdmaxalltick: "มูลค่าเต็มรวมทั้งตั๋ว",
      amnweightvalalltick: "น้ำหนักชั่งรวม(กรัม)",
      amnqtyunittick: "จำนวน",
      amnanalysvalnew: "ยอดชำระ",
      amnanalysvalreal: "ยอดชำระจริง",
      amninputmoneytypenew: "จำนวนเงินที่จ่าย",
      assetimg: "รูปทรัพย์",
      viewimg: "กดเพื่อดูรูป",
      amnassetdatasinfo: "คำสั่งข้อมูลรายการ",
      baluseable: "  ใช้ได้",
      amntransfer_promt_mobile: "พร้อมเพย์เบอร์มือถือ",
      amntransfer_promt_taxid: "พร้อมเพย์เลขบัตรประชาชน",
      toaccname: "ชื่อบัญชีของผู้รับเงินโอน",
      effectivedate: "วันที่มีผลบังคับใช้",
      amnbankaccno2: "เลขที่บัญชี/เบอร์มือถือ/เลขบัตรประชาชน",
      amnbring: "ยอดยกมา",
      amnqtypiece: "จำนวนชิ้น",
      lasttrf: "วันที่ทำธุรกรรม",
      monthlyprof: "เสียดอกเบี้ยเดือนละ",
      currentprof: "ดอกเบี้ยปัจจุบัน",
      miniweight: "นน.(ก)",
      issuedate: "วันที่ทำรายการ",
      deferman: "จัดการของผลัด",
      deferhis: "ประวัติการผลัด",
      deferpast: "จำนวนครั้งที่เคยผลัด",
      bcustomer: "ข้อมูลลูกค้าสาขา",
      bstage: "สถานะบัญชีธนาคาร",
      ticketdetail: "รายละเอียดทรัพย์บนตั๋ว",
      amnreporterrorticket: "รายงานธุรกรรมตั๋วเสีย",
      grandtotal: "ยอดรวม",
      paytype: "ประเภทเงิน",
      receivedpaid: "เงินที่รับ/จ่าย",
      assessor: "ผู้ประเมิน",
      channelpay: "ช่องทางการชำระ",
      category: "ประเภทการเงิน",
      inshop: "ภายในโรงรับจำนำ",
      outshop: "ภายนอกโรงรับจำนำ",
      expense: "ค่าใช้จ่าย",
      accclose: "ปิดบัญชี",
      transtype: "ประเภทธุรกรรม",
      transcash: "โยกเงินระหว่างบัญชีเงินสดหน้างาน",
      transbank: "โยกเงินระหว่างบัญชีธนาคารและบัญชีเงินสด",
      transin: "โยกเงินระหว่างบัญชีธนาคาร (ภายใน)",
      transout: "โยกเงินระหว่างบัญชีธนาคาร (ภายนอก)",
      genexp: "ค่าใช้จ่ายทั่วไป",
      cashshort: "เงินขาด",
      cashover: "เงินเกิน",
      unreccash: "เงินรอบันทึก",
      deposite: "ฝากเงิน",
      withdraw: "ถอนเงิน",
      listtype: "ประเภทรายการ",
      transac: "ธุรกรรม",
      update: "อัพเดตข้อมูล",
      oldduedate: "วันครบกำหนดเดิม",
      newduedate: "วันครบกำหนดใหม่",
      historyseize: "ประวัติตั๋วหาย & อายัด",
      informlost: "แจ้งตั๋วหาย",
      informseize: "แจ้งอายัด",
      copyprint: "พิมพ์สำเนาต้นขั้ว",
      officername: "ชื่อเจ้าหน้าที่",
      position: "ตำแหน่ง",
      placeon: "ที่ทำการ",
      tel: "เบอร์โทร",
      caseno: "เลขที่คดี",
      fromplace: "ของที่ทำการ",
      unseize: "ปลดอายัด",
      amnclosecashdrawer: "ปิดเงินสดหน้างาน",
      fromsource: "แหล่งเงิน",
      tosource: "แหล่งเงินปลายทาง",
      gticket: "รวมตั๋ว/แยกตั๋ว",
      gpay: "รวมรับ/จ่าย",
      jobId: "เลขที่งาน",
      prointerest: "ดอกเบี้ยโปรโมชั่น",
      periodpromotion: "ระยะเวลาโปรโมชั่น",
      noexp: "ตลอดชีพ",
      statusticketlost: "สถานะตั๋วหาย",
      refnolost: "เลขอ้างอิงตั๋วหาย",
      amnlocationasset: "สถานที่เก็บทรัพย์",
      num: "เลขลำดับ",
      oldname: "ชื่อเดิม",
      represent: "ทำธุรกรรมแทน",
      changeName: "ธุรกรรมเปลี่ยนชื่อ",
      amninputmoneyreceive: "จำนวนเงินที่รับ",
      orderinfo: "ข้อมูลรายการ",
      orderresult: "ผลลัพธ์",
      qrscan: "สแกนคิวอาร์โค้ด",
      refnumber: "เลขตั๋ว",
      trayId: "เลขที่ถาด",
      copyData: "คัดลอกข้อมูล",
      imgaddnew: "เพิ่มภาพจาก",
      lostcount: "จำนวนครั้งที่เคยแจ้งตั๋วหาย",
      tupload: "เพิ่มภาพ",
      managedefer: "จัดการผลัด",
      historyview: "ดูประวัติ",
      managelost: "จัดการตั๋วหาย",
      managefreeze: "จัดการอายัดตั๋ว",
      fileview: "ดูไฟล์แนบ",
      printticketcopy: "พิมพ์สำเนาตั๋ว",
      printticketlost: "พิมพ์ใบรับแจ้งตั๋วหาย",
      unfreeze: "ปลดอายัด",
      updatelost: "แก้ไขข้อมูลตั๋วหาย",
      updatefreeze: "แก้ไขข้อมูลอายัดตั๋ว",
      migraref: "เลขที่อ้างอิงย้ายระบบ",
      statuspay: "สถานะพร้อมชำระ",
      amnmigflow: "จำนวนธุรกรรมย้ายระบบ(บาท)",
      refnumberold: "เลขตั๋วเก่า",
      refnumbernew: "เลขตั๋วใหม่",
      docaddress: "ตามเอกสาร",
      persontinfo: "ข้อมูลคนทำธุรกรรม",
      tkOwner: "ชื่อเจ้าของตั๋ว",
      fingerInfo: "ลายนิ้วมือลูกค้า",
      fingerInfo1: "ลายนิ้วมือที่ 1",
      fingerInfo2: "ลายนิ้วมือที่ 2",
      receivem: "โรงรับ",
      paym: "โรงจ่าย",
      fee: "ค่าธรรมเนียม",
      numOfCancel: "จำนวนตั๋วเสีย",
      numOfTerminate: "จำนวนธุรกรรมที่ยกเลิก",
      transc_sub: "ประเภทย่อย",
      recbalance: "ขาด/เกิน",
      cuttrans: "ธุรกรรมลูกค้า",
      recmoneybiz: "จัดการกระทบยอดบัญชี",
      recmoneyhis: "ประวัติกระทบยอดบัญชี",
      amnclosebankdrawer: "ปิดยอดเงินธนาคาร",
      amnbankdrawerman: "จัดการปิดยอดเงินธนาคาร",
      source_others: "แหล่งเงินอื่น",
      ttreceivem: "รวมโรงรับทั้งหมด",
      ttpaym: "รวมโรงจ่ายทั้งหมด",
      ttfee: "รวมค่าธรรมเนียมทั้งหมด",
      amountop: "จำนวนเงินเริ่มต้นวัน",
      amountcl: "จำนวนเงินปิดรอบ",
      detfinanceinfo: "รายละเอียดการชำระ",
      balclosecash: "ยอดยกมาล่าสุด",
      sanctionmsg: "พบข้อมูล",
      docnum: "เลขที่เอกสาร",
      reportreconcile: "สรุปกระทบยอดบัญชี",
      retry: "ลองอีกครั้ง",
      passbk: "โอน K-Cash Connect",
      suminfo: "สรุปสต็อก",
      direct_lo: "ยืมเงินกรรมการ",
      sell_pr: "ขายสินค้า",
      resend_otp: "ขอรหัส OTP อีกครั้ง",
      reotptime: "ขอรับรหัส OTP ใหม่ได้ใน ",
      stweight: "น้ำหนัก",
      reqotpcode: "ขอรหัส OTP",
      ovrtransnor: "สรุปภาพรวมธุรกรรมปกติ",
      ovrtransmig: "สรุปภาพรวมธุรกรรมย้ายระบบ",
      valticket: "มูลค่าตั๋ว",
      sumvalticket: "รวมมูลค่าตั๋วทั้งหมด",
      transmig: "ย้ายระบบ",
      editafcom: "จำนวนการแก้ไขข้อมูลทรัพย์",
      status_finish: "เสร็จสิ้น",
      status_freeze: "อายัด",
      status_termi: "ยกเลิก",
      stockno: "เลขที่สต็อก",
      sumintticket: "รวมมูลค่าดอกเบี้ยทั้งหมด",
      bank_ov: "เงินในระบบขาด",
      bank_sh: "เงินในระบบเกิน",
      cash_ov: "เงินในเก๊ะเกิน",
      cash_sh: "เงินในเก๊ะขาด",
      txfee: "ค่าธรรมเนียมธุรกรรม",
      pawnvalpv: "ราคาจำนำก่อนหน้า",
      oldsystemsinfo: "ข้อมูลสำหรับระบบเก่า",
      pawntktotal: "ยอดตั๋วจำนำ",
      pawntotal: "ยอดจำนำ",
      newtktotal: "ยอดหน้าตั๋วใหม่",
      oldtktotal: "ยอดหน้าตั๋วเก่า",
      redeemtktotal: "ยอดตั๋วไถ่ถอน",
      redeemtotal: "ยอดไถ่ถอน",
      allloc: "ทั้งโรงรับจำนำ",
      workProcess: "จำนวนงานค้างทั้งหมดในระบบ",
      workQ01: "งานที่หลงจู๊",
      workQ02: "งานที่เสมียนไทย",
      workQ03: "งานที่เสมียนจีน",
      workQ04: "งานที่เกา",
      workQpay: "การชำระ",
      numberactive: "จำนวนตั๋วทั้งหมดในระบบ",
      numberpostpone: "จำนวนตั๋วที่ผลัดทั้งหมดในระบบ",
    },
  },
  en: {
    langs: {
      tha: "Thai",
      eng: "English",
    },
    apps: {
      name: "Buddy Money",
      master: "Master",
      general: "General",
      setting: "Setting",
      gdashboard: "Dashboard",
      gtransaction: "Transaction",
      gmanage: "Manage Transactions",
      gticket: "Ticket (Group / Split)",
      gfinance: "Finance",
      gsplitasset: "Split Assets",
      ghistory: "History",
      gticketchain: "Ticket Chain",
      gcashmove: "Cash Movement",
      gtrans: "Transactions Management",
      gdeposit: "Customer Deposit",
      gcustomer: "Customer Info",
      gport: "Customer Port",
      report: "Report",
      gunitover: "Unit Overdue",
      gdeferitems: "Unit Resume",
      mbank: "Bank",
      mwarning: "Personal Warning",
      millegal: "Illegal Assets",
      mtypeassets: "Asset info",
      mprefix: "Prefix of name",
      maddress: "Address Info",
      mholiday: "Holidays",
      mtypecustomer: "Customer type",
      mtypedocument: "Document type",
      mtypeunit: "Unit type",
      mpawn: "Pawnshop bank",
      mcash: "Cash account",
      mbanknote: "Bank Note type",
      mbiz: "Business Type",
      sopen: "Open Branch",
      ssetbranch: "Branch Settings",
      spre: "Pre-print & Running",
      sinterest: "Interest",
      sstock: "Stock",
      sgold: "Gold Price",
      srole: "Security Role",
      ssetup: "Security Setup",
      suser: "User",
      acprofile: "My Profile",
      acsetting: "Settings",
      aclogout: "Logout",
      massetgroup: "Asset Group",
      financial: "Financial",
      cashdrawer: "Cash Drawer",
      customer: "Customer",
      seize: "Lost Ticket & Seize",
      location: "Locations",
      stock: "Stock",
      reportall: "Summary of operations",
      reportday: "Summary of daily operations",
      reportpawn: "Summary of pawn actions",
      reportprofit: "Summary of profit actions",
      reportaddcap: "Summary of add capital actions",
      reportreducecap: "Summary of reduce capital actions",
      reportredeem: "Summary of redeem actions",
      reportcancel: "Summary of cancelled actions",
      reportdefer: "Summary of closing expiry ticket and defer actions",
      reporttop: "Summary of Top 20",
      reportpay: "Summary of trancactions",
      exportitemoverdue: "Export of overdue items",
      stocks: "Stock",
      checktxn: "Check Transactions",
      verifypayment: "Verify Payment",
      goverall: "Overall Payment",
      fgpwd: "Forget Password",
      rspwd: "Reset Password",
      rspwdlb: "Choose select for getting OTP Code",
      verifypayment2: "Verify Payment2",
      version: "Version",
      reporterrorticket: "Summary of error ticket",
      closecashdrawer: "Close Cash Drawer",
      reconcile: "Reconcile",
      closebankdrawer: "Close Bank Drawer",
    },
    heads: {
      men: "Menu",
      clo: "Close",
      hom: "Home",
      new: "News/Promotions",
      pro: "Products/Services",
      abo: "About us",
      con: "Contact",
      ope: "Register",
      log: "Log in",
      por: "Portfolio",
      his: "History",
      res: "Reservation",
      cle: "Clear Port",
      not: "Notification",
      acc: "Settings",
      out: "Log out",
      rem: "Remember me",
      usr: "Username",
      pwd: "Password",
      ema: "Email",
      mob: "Mobile",
      adr: "Address",
      fgpwd: "Forget Password",
      rspwd: "Reset Password",
      rspwdlb: "Choose select for getting OTP Code",
    },
    buttons: {
      okay: "OK",
      back: "Back",
      prev: "Previous",
      next: "Next",
      canc: "Cancel",
      conf: "Confirm",
      save: "Save",
      acce: "Accept",
      decl: "Decline",
      open: "Open",
      clos: "Close",
      clea: "Clear",
      dele: "Delete",
      sele: "Select",
      comp: "Completed",
      uplo: "Upload",
      send: "Send",
      sear: "Search",
      addd: "Add",
      show: "Show",
      hide: "Hide",
      remo: "Remove",
      chan: "Change",
      sett: "Settings",
      impo: "Add new",
      edit: "Edit",
      file: "Select File",
      split: "Split Items",
      seed: "See Detail",
      print: "Print",
      sscan: "Start Scan",
      nscan: "New Scan",
      load: "Loading ...",
      apprv: "Approved",
      rejec: "Reject",
      opencam: "Open Camera",
      closecam: "Close Camera",
      takePhoto: "Take a photo",
      preview: "Preview",
      retakePhoto: "Re Take a photo",
      canct: "Cancel Ticket",
      printp: "Print Ticket",
      printpb: "Print Cover Ticket",
      savePrint: "Save & Print",
    },
    content: {
      gtotal: "Total",
      act: "Actions",
      en: "English",
      th: "Thai",
      mbank: "Bank",
      mbankname: "Bank name",
      mlogo: "Logo",
      mbankpshort: "Short code",
      mbankp: "Pawnshop Bank",
      mcolor: "Color",
      note: "Notice",
      mcash: "Cash Account Lists",
      mcashname: "Cash account name",
      mbanknote: "Bank Note Lists",
      mbanknotetype: "Bank note type",
      mbanknotevalue: "Rate value",
      maddress: "Address Info",
      detail: "Detail",
      date: "Date",
      mholiday: "Holidays",
      munit: "Units",
      massetgroup: "Asset Group",
      mtypedoc: "Document type",
      add1: "Nation",
      add2: "State",
      add3: "Province",
      add4: "City",
      add5: "Post Code",
      gmoney: "Money Management",
      history: "History",
      gmoneybiz: "Transactions Management",
      sdate: "From date",
      edate: "To date",
      refno: "Ref No.",
      trans: "Transactions",
      dant: "Date/Time",
      file: "Files",
      amount: "Amount",
      gmoneyhis: "Transactions History",
      type: "Type",
      glisttrans: "Transaction Lists",
      gmoneytype: "Money Type",
      gdepohis: "Customer Deposit History",
      gdepoman: "Customer Deposit Management",
      names: "Name-Lastname",
      cnamelist: "Customer name lists",
      cname: "Customer Name",
      ccode: "Customer Code",
      cnameth: "Name-Lastname(TH)",
      cnameen: "Name-Lastname(EN)",
      cidcard: "ID Card",
      cmobile: "Mobile",
      cgrade: "Customer Grade",
      optall: "All",
      gcashmove: "Cash Movement",
      customer: "Customer",
      gcashmovetype: "Movement Type",
      item: "Item",
      depo: "Deposit",
      withd: "Withdraw",
      total: "Total",
      expfile: "Export to",
      millegal: "Illegal Assets",
      assetinfo: "Asset Info",
      serial: "Serial no.",
      refer: "Source",
      docno: "Card ID/Document no.",
      docdate: "Document Date",
      criminal: "Criminal Case",
      agency: "Agency",
      defect: "Defect/Spacial",
      mwarning: "Personal Warning",
      doctype: "Document Type",
      cardno: "Card no.",
      warnlvl: "Warning Level",
      select: "Please select",
      ssetbranch: "Branch Settings",
      status: "Status",
      activity: "Activity",
      setallopen: "(On-Off) For all systems",
      detailallopen:
        "On (Open all systems), Off (Close all systems), Available only for ADMIN",
      setfixbackend: "(On-Off) For all systems",
      detailfixbackend:
        "On (Open all systems), Off (Close all systems), Available only for ADMIN",
      day: "Day",
      onoff: "On-Off",
      opentime: "Open Time",
      to: "to",
      closetime: "Close Time",
      sun: "Sunday",
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      close: "Close",
      open: "Open",
      gticketchain: "Ticket Chain",
      ticketno: "Ticket no.",
      allweight: "Total Weight",
      allqty: "Total QTY",
      allprice: "Total Price",
      gcustomer: "Customer Info",
      gcustomerman: "Customer Info Management",
      cstatus: "Customer Status",
      activate: "Activated",
      lastact: "Last active",
      resetpwd: "Reset Password",
      cardtype: "Card Type",
      pcissue: "Place of issue",
      cdissue: "Date of issue",
      cdexpire: "Expire date",
      cprefix: "Prefix",
      cnam: "Name",
      clsnam: "Lastname",
      cbirth: "Birthdate",
      cage: "Age",
      crelig: "Religion",
      cblood: "Blood",
      cnation: "National",
      cstage: "Pawner Status",
      caddress: "Address",
      cstate: "Province",
      csprov: "District",
      cscity: "Sub-district",
      cemail: "Email",
      cupload: "Choose file",
      cbankinfo: "Bank Account Info",
      cbankname: "Bank name",
      cbankno: "Bank account no.",
      cbankacc: "Bank account name",
      cselectuse: "Selected",
      cdefault: "Default",
      cintrate: "Interest Rate Per Month",
      cintrate1: "Normal",
      cintrate2: "Extra",
      csign: "Signatures",
      cfprint: "Finget Prints",
      cabove: "Ralations",
      crlname: "Search name and lastname",
      gdashboard: "Dashboard",
      byday: "By date",
      byweek: "By week",
      bymonth: "By date",
      gport: "Customer Port",
      grelation: "Relational Port",
      allassets: "All Assets",
      holdon: "Hold on",
      totunit: "Total QTY",
      totprice: "Total Prices",
      totvalue: "Total Values",
      totmaxval: "Total Max Asset Value",
      totlimit: "Total Pawn Value",
      personhis: "Personal History",
      totdeposit: "Customer Wallet",
      holding: "hold on",
      nothold: "Not hold",
      statusticket: "Status Ticket",
      curency: "BAHT",
      time: "Time",
      actpawn1: "Pledge",
      actpawn2: "Interest",
      actpawn3: "Add Capitals",
      actpawn4: "Reduce Capitals",
      actpawn5: "Redeem",
      actpawn6: "Split Redeem",
      amnenter: "Customers who make transactions(persons)",
      amnqty: "Summary of transactions(times)",
      amnmoney: "Summary of money transactions(baht)",
      amndue: "Summary of tickets due(sheets)",
      amnticketdueactived: "Tickets due that cleared on today(sheets)",
      amnticketdued: "Tickets due on today(sheets)",
      amnticketoveractived: "Tickets overdued on yesterday(sheets)",
      amnticketoverd: "Tickets will overdue on today(sheets)",
      amnticketdueactivew: "Tickets due that cleared by week(sheets)",
      amnticketduew: "Tickets due by week(sheets)",
      amnticketoveractivew: "Tickets overdued by week(sheets)",
      amnticketoverw: "Tickets will overdue by week(sheets)",
      amnticketdueactivem: "Tickets due that cleared by month(sheets)",
      amnticketduem: "Tickets due by month(sheets)",
      amnticketoveractivem: "Tickets overdued by month(sheets)",
      amnticketoverm: "Tickets will overdue by month(sheets)",
      amncash: "Cash",
      amnbankacc: "Bank account",
      amntotbaht: "Summary",
      amndrawer: "Drawer",
      amnlistcash: "Cash items",
      amnlistremain: "Remaining items",
      amnremain: "Remaining",
      amnpay: "Pay",
      amnget: "Receive",
      amntransfer: "Transfer Money Account Number",
      amntotaltransact: "Grand Total",
      amnduedate: "Due date",
      amnduedatenew: "Due date(new)",
      amnduedateadd: "Days added",
      amninterest: "Interest",
      amnticketduelist: "Ticket due lists",
      amnsafe: "Cash in safe",
      amntypecustomer: "Customer type",
      amncredit: "Credit",
      amnchannel: "Channel",
      amntimestart: "Start Time",
      amntimeend: "End Time",
      amncashflow: "Cash Flow",
      amnincome: "Income",
      amnoutcome: "Outcome",
      amnprofit: "Profit",
      amndeposit: "Deposit",
      amnwithdraw: "Withdrawal",
      amnpawnlist: "Assets List",
      amnsplitlist: "Split Asset List",
      amnassetowner: "Asset Owner",
      amncreditget: "Limit Received",
      amncredituse: "Limit Used",
      amncreditremain: "Limit Remaining",
      amnassetdetail: "Asset Detail",
      amnassetdatas: "Asset Ticket Informations",
      amnweightval: "Weight(grams)",
      amnweightvalall: "Total Weight(grams)",
      amnanalysval: "Price",
      amnanalysvalall: "Total Price",
      amnmaxval: "Full Value",
      amnmaxvalall: "Total Full Value",
      amnqtydiamond: "QTY(Diamond)",
      amnqtyunit: "QTY(Units)",
      amnqtyunitall: "Total QTY(Units)",
      amnfingerprint: "Finger Print",
      amngold: "Gold",
      amndiamond: "Diamond",
      amnframe: "Monk Frame",
      amncopper: "Copper",
      amnwatch: "Watch",
      amnothers: "Others",
      amnamulet: "Amulet",
      amnsilver: "Silver",
      amngem: "Gem",
      amntypeassets: "Asset info",
      amnstateacting: "on Process",
      amnstateending: "Ready To Pay",
      amnsearchticker: "Ticket Search",
      amndatetime: "Date/Time",
      amnassetgroup: "Asset Type",
      amnbiz: "Business Type",
      amnoldmessage: "Original Asset Detail",
      amngroupticket: "Ticket Group",
      amnticketlist: "Ticket List",
      amnticketmanage: "Ticket Management",
      amnonprocess: "on Process",
      amncancelticket: "Cancel Ticket",
      amnbankprocess: "Bank Process",
      amncanceleditnew: "Cancel to edit ticket",
      amnfinanceno: "Finance no.",
      amnfinancestatus: "Finance Status",
      amnfinanceok: "Completed",
      amnfinancenot: "No action",
      amnfinancepartial: "Partial",
      amnfinanceremain: "Total remaining",
      amnfinancedoneall: "Total transactions",
      amnfinancesearch: "Finance Search",
      amnfinancelist: "Finance Lists",
      amnfinancenmanage: "Finance Management",
      amnbankinfo: "Bank Account info",
      amnselectlist: "Select item",
      amnbankaccno: "Bank Account no.",
      amnbankactive: "Active",
      amnfinanceinput: "Receive",
      amnfinanceoutput: "Paid",
      amnfinancetype: "Clear Type",
      amnfinancetotal: "Total Clear",
      amnfinanceinfo: "Finance Info",
      amnfinanceprocess: "Finance Process",
      amncreditcard: "Credit Card",
      amncheque: "Cheque",
      amntypetrans: "Transcaction Type",
      amnhowtomoney: "Cheque/Credit Card no./Ref.",
      amninputmoneytype: "Paid/Receive",
      amnsourcemoney: "Source of Money",
      amnqrrunapp: "scan QR code to continueing on application.",
      amnfromdrawer: "From drawer",
      amnfrombankacc: "From bank account",
      amnfinancenote: "Transaction Notice",
      amnpayremain: "Paid Remaining",
      amnreceiveremain: "Receive Remaining",
      amnrelatenum: "Relation Amounts",
      amnaddnew: "New Item",
      amndeferitems: "Unit Resume",
      amnuser: "User",
      amnusermanage: "User Management",
      amnusername: "Username",
      amnusernick: "Nickname",
      amnadmin: "Administrator",
      amnuserrole: "User Role",
      amnuserright: "User Right",
      amneffectdate: "Effect date",
      amnenddate: "End date",
      amnsplitasset: "Split Assets",
      amnsplitassetman: "Split Assets Management",
      amntypegold: "Gold Assest",
      amnnotgold: "No Gold Assest",
      amndefertime: "Defer Times",
      amnrecheckfinance: "Validations",
      amnconfirmfinance: "Confirmations",
      amnamountall: "All Amount",
      amnbillno: "Bill no.",
      amnbilldate: "Bill date",
      amndillinfo: "Bill Info",
      amnrecheckdone: "Checked",
      amnreturn: "Return",
      amnotpcode: "OTP Codes",
      amnotpref: "OTP Ref.",
      amnotptime: "Your OTP is active within ",
      amnmin: " minutes",
      amnfinancial: "Financial",
      amncashdrawer: "Cash Drawer",
      amnseize: "Lost Ticket & Seize",
      amnlocation: "Locations",
      amnstock: "Stock",
      amnreportall: "Summary of operations",
      amnreportday: "Summary of daily operations",
      amnreportpawn: "Summary of pawn actions",
      amnreportprofit: "Summary of profit actions",
      amnreportaddcap: "Summary of add capital actions",
      amnreportreducecap: "Summary of reduce capital actions",
      amnreportredeem: "Summary of redeem actions",
      amnreportcancel: "Summary of cancelled actions",
      amnreportdefer: "Summary of closing expiry ticket and defer actions",
      amnreporttop: "Summary of Top 20",
      amnreportpay: "Summary of trancactions",
      amnexportitemoverdue: "Export of overdue items",
      amnstocks: "Stock",
      amncashdrawerman: "Cash Drawer Management",
      amncashdrawername: "Cash drawer name",
      amncashdrawerbalance: "Balance by daily",
      amncashdrawerdiff: "Difference",
      amnmoneylimit: "Moner Limit",
      amnvalue: "Value",
      amnticketlost: "Lost Ticket",
      amnseizeact: "Seize",
      amnticketlostman: "Lost Ticket Management",
      amnseizeactman: "Seize Management",
      amnpastsearch: "Previous Search",
      amndantinfo: "Date/Time Info",
      amnbizinfo: "Type Info",
      amncurrent: "Current",
      amnmaker: "Maker",
      amnchecker: "Checker",
      amnwaitreport: "Wait to Report",
      amnfinreport: "Finished Report",
      amnsuspend: "Suspend Item",
      amnsuspend2: "Formal Suspend Item",
      amnbanned: "Banned Item",
      amninformlost: "Report",
      amnunlock: "Unlock",
      amnreason: "Reason",
      amnseizetype: "Seize Type",
      amnunitover: "Unit Overdue",
      amnpawndate: "Pawn date",
      amnstatusdefer: "Defer",
      amnstatusover: "Overdue",
      amnqtyitem: "Quantity",
      amnpawnval: "Pawn Value",
      amnprofitval: "Profit Value",
      amnoverduedays: "Overdue Days",
      amnperiodtime: "Period of Time",
      amnduewithin: "Closely Due in",
      amnlevelno: "Level",
      amnlresult: "Result",
      amnassetstatus: "Asset Status",
      amntotalpawnval: "Total Pawn Value",
      amntotalpawnmax: "Max Pawn Value",
      amntotalpawnmin: "Min Pawn Value",
      amntotalpawnavg: "Average Pawn Value",
      amndaytotalpawnmax: "Max Period of Time",
      amndaytotalpawnmin: "Min Period of Time",
      amndaytotalpawnavg: "Average Period of Time",
      amnassetimage: "Asset Image",
      amndaytotalpawn: "Pawn Period of Time",
      amntimes: "Times",
      amntotalval: "Total Value",
      amntotalvalmax: "Full Value",
      amntotalvalmin: "Min Value",
      amntotalvalavg: "Average Value",
      amntotalavgval: "Total Average Value",
      amnticketold: "Old Ticket no.",
      amnticketnew: "New Ticket no.",
      amntypepawn: "Pawn Type",
      amnnewaction: "New Item",
      amnoldaction: "Old Item",
      amnpawnvalall: "Total Pawn Value",
      amnevaluate: "Evaluation Value",
      amndifference: "Difference Value",
      amnreceiveprofit: "Receive Profit",
      amndatepayprofit: "Date of Profit Paid",
      amnreceiptno: "Receipt no.",
      amndiscount: "Discount",
      amnexceptprofit: "Except Profit",
      amnrealprofit: "Get Profit",
      amnpaytimeprofit: "All Profit Times",
      amnvaluecompare: "Profits compare with capital(%)",
      amnaddcapdate: "Add Capital Date",
      amnreducecapdate: "Reduce Capital Date",
      amnevalueadd: "Add Capital Value",
      amnevaluereduce: "Reduce Capital Value",
      amnnewtotalprofit: "New Profit Total",
      amndifferencepawn: "Difference Pawn Value",
      amndifferenceprofit: "Difference Profit Value",
      amnallredeem: "All Redeem",
      amnredeemtype: "Redeem Type",
      amnredeemdate: "Redeem Date",
      amntotalprofit: "Profit Total",
      amnevalueredeem: "Redeem Value",
      amnticketcancelno: "Cancel Ticket no.",
      amnbookno: "Book no.",
      amnno: "no.",
      amnrealoutput: "Real Output",
      amndeferdate: "Defer Date",
      amnfinreceive: "Received",
      amnfinpay: "Paid",
      amnbanktransfer: "Bank Transfer",
      amnfinancetypes: "Financial Type",
      amnclearingdate: "Clearing Date",
      amnclearingamount: "Clearing Amount",
      amnclearingremain: "Clearing Remain",
      amncustomerbankacc: "Customer Bank Account",
      amntotalinput: "Total Input",
      amntotaloutput: "Total Output",
      amngrandtotal: "Grand Total",
      amnindex1: "Top 20 of customers who hold the most tickets.",
      amnindex2: "Top 20 of customers who have the most pawn value",
      amnindex3: "Top 20 of customers which have the most of lost pledges",
      amnindex4: "Top 20 of assets which have the most value",
      amnindex5: "Top 20 of tickets which have the most value",
      amnindex6:
        "Top 20 of customers who have done the most pawn actions and redeem actions",
      amnindex7:
        "Top 20 the most percentage value (%) of customers who paid profit compared with capital",
      amnanalysvalold: "Price(Old)",
      amnanalysvalallold: "Total Price(Old)",
      amnanalysvalallnew: "Total Price(New)",
      amnfingerscan: "Finger Scan",
      amnstartscan: "Press Start Scan",
      amnmatchscan: "Scan result is matched.",
      amnnotmatchscan: "Finger print not found.",
      amnpaycompleted: "Paid",
      amnallticket: "Amount Tickets",
      amnhdweightrest: "Weight Maintain",
      amnhdweightall: "All Weights",
      amnhdanalysrest: "Pawn Value Maintain",
      amnhdanalysall: "All Pawn Value",
      amnhdmaxrest: "Full Value Maintain",
      amnhdmaxall: "All Full Value",
      amnhdanalysold: "Pawn Value(Old)",
      amnhdanalysnew: "Pawn Value(New)",
      amnhdvalticket: "All Tickets Value",
      amnhdacreturnticket: "Return Ticket",
      amnhdcfreturnticket: "Return Ticket Completed",
      amnmaintaindate: "Maintain(Days)",
      transc: "Categories",
      ctranstin: "Within Pawnshop",
      ctranstout: "Outside Pawnshop",
      ctranstexp: "Expense",
      ctranstacc: "Account closure",
      transt: "Transaction Types",
      ctranstca: "Transfer between cash accounts",
      ctranstbc: "Transfer between bank account and cash account",
      ctranstii: "Inside - Inter-account fund transfer",
      ctranstoi: "Outside - Inter-account fund transfer",
      ctranstge: "General Expense",
      ctranstcs: "Cash Shortage",
      ctranstco: "Cash Overage",
      ctranstuc: "Unrecorded Cash",
      ctranstde: "Deposit",
      ctranstwi: "Withdraw",
      ctransttr: "Transfer",
      amntkbefo: "Previous Ticket No. ",
      amntknew: "New Ticket No.",
      amncrcard: "Credit Card",
      amncollec: "Collect Asset",
      amnpendin: "Pending",
      amnticketoff: "Ticket Off",
      amnticketedit: "Edit Ticket",
      amngroupticketsearch: "Search Group Ticket",
      amnalljobs: "All Jobs",
      amnlastmaker: "Lastest Maker",
      amnchecktxn: "Check Transactions",
      amnconfirmtxn: "Confirm Transactions",
      amndetailtxn: "Transaction details",
      amntakephoto: "Capture Image",
      amnretakephoto: "New Capture",
      amnnewjob: "New Job",
      amnjobcode: "Job Code",
      amnselectauto: "Select Auto Ticket",
      amnasgroup: "as Payment Group",
      amnastype: "as Transaction Type",
      amntxnoverall: "Overall Payment",
      amnendjob: "Close Job",
      cexintra: "Extra Information",
      cchacount: "Character Count",
      cenanoti: "Enable Notification Alerts",
      payside: "Pay Side",
      gprice: "Group/Un Group Pawn Value",
      gweight: "Group/Un Group Weight",
      amnhdweightalltick: "All Tickets Weights(grams)",
      amnhdanalysalltick: "All Tickets Price",
      amnhdmaxalltick: "All Tickets Full Value",
      amnweightvalalltick: "Total Weight(grams)",
      amnqtyunittick: "QTY",
      amnanalysvalnew: "Total Amount",
      amnanalysvalreal: "Total Paid",
      amninputmoneytypenew: "Paid",
      assetimg: "Images Asset",
      viewimg: "View Images",
      amnassetdatasinfo: "Asset Informations",
      baluseable: "  Available",
      amntransfer_promt_mobile: "Promptpay (Mobile)",
      amntransfer_promt_taxid: "Promptpay (Tax Id)",
      toaccname: "Account Name",
      effectivedate: "Effective Date",
      amnbankaccno2: "Bank Account no./Mobile/Tax Id",
      amnbring: "Bring forward",
      amnqtypiece: "Quantity",
      lasttrf: "Active Date",
      monthlyprof: "Profit Monthly",
      currentprof: "Current Profit",
      miniweight: "w.(g)",
      issuedate: "Issue date",
      deferman: "Defer Management",
      deferhis: "Defer History",
      deferpast: "Defer Times",
      bcustomer: "Customer Shop Info",
      bstage: "Bank Status",
      ticketdetail: "Asset Ticket Detail",
      amnreporterrorticket: "Summary of error ticket",
      grandtotal: "Grand Total",
      paytype: "Pay Type",
      receivedpaid: "Received / Paid",
      assessor: "Assessor",
      channelpay: "Payment Method",
      category: "Categories",
      inshop: "Within Pawnshop",
      outshop: "Outside Pawnshop",
      expense: "Expense",
      accclose: "Account closure",
      transtype: "Transaction Types",
      transcash: "Transfer between cash accounts",
      transbank: "Transfer between bank account and cash account",
      transin: "Inside - Inter-account fund transfer",
      transout: "Outside - Inter-account fund transfer",
      genexp: "General Expense",
      cashshort: "Cash Shortage",
      cashover: "Cash Overage",
      unreccash: "Unrecorded Cash",
      deposite: "Deposit (client deposit funds into portfolio)",
      withdraw: "Withdraw (client withdraw funds to settle a bill)",
      listtype: "List Type",
      transac: "Transaction",
      update: "Update",
      oldduedate: "Old Due Date",
      newduedate: "New Due Date",
      historyseize: "Lost Ticket & Seize History",
      informlost: "Lost Ticket Information",
      informseize: "Seize Information",
      copyprint: "Original Copy Print",
      officername: "Officer Name",
      position: "Position",
      placeon: "Place on",
      tel: "Telephone no.",
      caseno: "Case no.",
      fromplace: "Form Place",
      unseize: "Unseize",
      amnclosecashdrawer: "Cash Drawer",
      fromsource: "From Source of Money",
      tosource: "To Source of Money",
      gpay: "Total Receive/Pay",
      jobId: "Job ID",
      prointerest: "Interest Promotion",
      periodpromotion: "Promotion Duration",
      noexp: "No Expiration Date",
      statusticketlost: "Status Ticket Lost",
      refnolost: "Ref Lost No.",
      amnlocationasset: "Locations",
      num: "No.",
      oldname: "Previously Name",
      represent: "Attorney",
      changeName: "Change Name",
      amninputmoneyreceive: "Receive",
      orderinfo: "Order Info",
      orderresult: "Result",
      qrscan: "Scan QR Code",
      refnumber: "Ticket Number",
      trayId: "Tray ID",
      copyData: "Copy Data",
      imgaddnew: "Source Images",
      lostcount: "Lost Count",
      tupload: "Add Images",
      managedefer: "Defer Management",
      historyview: "View History",
      managelost: "Lost Management",
      managefreeze: "Freeze Management",
      fileview: "View File",
      printticketcopy: "Print Copy Ticket",
      printticketlost: "Print Notice Lost Ticket",
      unfreeze: "Un Freeze",
      updatelost: "Update Lost",
      updatefreeze: "Update Freeze",
      migraref: "Migrate Refnumber",
      statuspay: "Status Pay",
      amnmigflow: "Summary of money migration(baht)",
      refnumberold: "Ticket Number(Old)",
      refnumbernew: "New Ticket Number(New)",
      docaddress: "According to document",
      persontinfo: "Transaction Information",
      tkOwner: "Ticket Owner Name",
      fingerInfo: "Customer Fingerprint",
      fingerInfo1: "Fingerprint No.1",
      fingerInfo2: "Fingerprint No.2",
      receivem: "Receive",
      paym: "Pay",
      fee: "Fee",
      numOfCancel: "Cancel Ticket",
      numOfTerminate: "Terminate Ticket",
      transc_sub: "Sub Categories",
      recbalance: "Balance",
      cuttrans: "Customer Transactions",
      recmoneybiz: "Reconcile Management",
      recmoneyhis: "Reconcile History",
      amnclosebankdrawer: "Bank Drawer",
      amnbankdrawerman: "Bank Drawer Management",
      source_others: "Others Source",
      ttreceivem: "Total Receive",
      ttpaym: "Total Pay",
      ttfee: "Total Fee",
      amountop: "Start Daily Amount",
      amountcl: "Close Amount",
      detfinanceinfo: "Finance Info Detail",
      balclosecash: "Beginning Balance Cash Drawer",
      sanctionmsg: "Data Found",
      docnum: "Document no.",
      reportreconcile: "Summary Reconcile",
      retry: "Try Again",
      passbk: "K-Cash Connect",
      suminfo: "Summary Stock",
      direct_lo: "Director Loan",
      sell_pr: "Selling Products",
      resend_otp: "Resend OTP",
      reotptime: "Resend will be avalible in ",
      stweight: "Weight",
      reqotpcode: "Send OTP",
      ovrtransnor: "Overall Normal Transactions",
      ovrtransmig: "Overall Migration Transactions",
      valticket: "Ticket Value",
      sumvalticket: "All Tickets Value",
      transmig: "Migration",
      editafcom: "Edit Asset After Complete",
      status_finish: "Finish",
      status_freeze: "Freeze",
      status_termi: "Terminate",
      stockno: "Stock No.",
      sumintticket: "All Interests Value",
      bank_ov: "Bank Over",
      bank_sh: "Bank Short",
      cash_ov: "Cash Over",
      cash_sh: "Cash Short",
      txfee: "Transaction Fee",
      pawnvalpv: "Previous Price",
      oldsystemsinfo: "Infomation Of Old Systems",
      pawntktotal: "Pawn Tickets",
      pawntotal: "Total Pawn",
      newtktotal: "New Tickets",
      oldtktotal: "Old Tickets",
      redeemtktotal: "Redeem Tickets",
      redeemtotal: "Total Redeem",
      allloc: "All Locations",
      workProcess: "Job On Hand",
      workQ01: "Shop Manager",
      workQ02: "Thai Clerk",
      workQ03: "Chinese Clerk",
      workQ04: "Vault Keeper",
      workQpay: "Payments",
      numberactive: "Number Of Ticket",
      numberpostpone: "Number Of Postpone Ticket",
    },
  },
};

export default messages;
