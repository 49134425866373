<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="cashDrawerTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-cashDrawer" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstCashDrawer"
              type="button" role="tab" aria-controls="firstCashDrawer" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amnclosecashdrawer") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-cashDrawer" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondCashDrawer"
              type="button" role="tab" aria-controls="secondCashDrawer" aria-selected="false"
              :disabled="listcashob.length === 0" @click="onClickTab('2')">
              {{ $t("content.amncashdrawerman") }} </button>
          </li>
        </ul>

        <div id="cashDrawerTabContent" class="tab-content">
          <div id="firstCashDrawer" class="tab-pane fade" role="tabpanel" aria-labelledby="first-cashDrawer">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t("content.amnclosecashdrawer") }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0"
                        :disabled="listcashob.length >= listcashAcc.length" @click="loadBalanceInfo">
                        <i class="material-icons material-symbols-outlined">summarize</i>
                        {{ $t("content.balclosecash") }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>


              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                    <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      fluid :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      fluid :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{
                      $t("content.amncashdrawername")
                    }}</label>
                    <select class="form-select" v-model="cashacc_search">
                      <option value=''> {{ $t("content.optall") }}
                      </option>
                      <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataApi" :disabled="start_date > end_date">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.amncashdrawername") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.file") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listclosecash" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_datetime(row.closingDateTime) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterCashacc(row.cashAccUid) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.totalAmount) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.remark }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.fundStatus }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              v-if="row.refDocUid !== '0'" @click="getFileRef(row)">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                            <material-button color="primary" data-bs-toggle="modal" data-bs-target="#attachmentsModal"
                              v-show="false" id="attachmenDialog">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listclosecash.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listclosecash.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="secondCashDrawer" class="tab-pane fade" role="tabpanel" aria-labelledby="second-cashDrawer">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amncashdrawerman") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">
                      {{ $t("content.amncashdrawername") }}</label>
                    <select class="form-select" v-model="cashAccUid">
                      <option value="" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <div class="d-flex align-items-center mb-1">
                      <label class="form-label ms-0">
                        {{ $t("content.file") }}
                      </label>
                    </div>
                    <input id="upload" type="file" accept="application/pdf,image/*" @change="addFile" />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t("content.mbanknote") }}</th>
                          <th>{{ $t("content.amnqtyitem") }}</th>
                          <th>{{ $t("content.amnvalue") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in cashDetails" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <InputNumber v-model="row.billCount" :min="0" autocomplete="off"
                              @focus="$event.target.select()" @update:modelValue="calValue(row)" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <InputNumber v-model="row.amount" :min="0" autocomplete="off"
                              @focus="$event.target.select()" @update:modelValue="calQuanity(row)" />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="2" class="text-lg font-weight-bold">
                            <b>{{ $t("content.gtotal") }}</b>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <b>{{ formatNumberDecimalString(total) }}</b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="col-lg-10">
                    <Textarea rows="3" v-model="remark" class="w-100" :placeholder="note" style="resize: none" />
                  </div>
                  <div class="col-lg-2">
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="confirmNewRecord"
                      :disabled="cashAccUid === '' || remark.trim() === ''">
                      <i class="material-icons material-symbols-outlined">save</i>
                      {{ btnSave }}
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import ProgressSpinner from 'primevue/progressspinner';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import ExampleFive from "@/examples/ExampleOne";

import { useCashAccountStore } from '@/stores/cashaccount'
import { useBankNoteStore } from '@/stores/banknote'
import { useCloseCashStore } from '@/stores/closecash'
import { useDocumentStore } from '@/stores/document'

const storec = useCashAccountStore()
const storeb = useBankNoteStore()
const storecc = useCloseCashStore()
const storedoc = useDocumentStore()

const { t } = useI18n();
const swal = inject("$swal");


const btnApprove = computed(() => t("buttons.apprv"))
const btnEdit = computed(() => t("buttons.edit"));
// const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"));
const btnDel = computed(() => t("buttons.remo"));
// const btnClose = computed(() => t("buttons.clos"))
// const btnFile = computed(() => t("content.file"))
const note = computed(() => t("content.note"));


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}



const onClickHandler = () => {
  updatePagination()
};


const calValue = (item) => {
  item.amount = item.multiply * item.billCount
  sumtotal()
  return item.amount
};

const calQuanity = (item) => {
  let billCount = item.amount / item.multiply
  if (Number.isInteger(billCount)) {
    item.billCount = Number(billCount)
  } else {
    swal("กรุณาตรวจสอบมูลค่าที่กรอก", '', "error");
    item.billCount = 0
  }
  sumtotal()

  return item.billCount
};


const sumtotal = () => {
  let sum = 0
  let bill = 0
  for (let i = 0; i < cashDetails.value.length; i++) {
    if (Number.isInteger(cashDetails.value[i].amount / cashDetails.value[i].billCount)) {
      sum = sum + Number(cashDetails.value[i].amount)
      bill = bill + Number(cashDetails.value[i].billCount)
    }
  }
  total.value = sum
  totalBillCount.value = bill
};



const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const cashacc_search = ref('');
const cashAccUid = ref('');
const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const shop_detail = ref(null)
const listcashAcc = ref([]);
const cashDetails = ref([]);
const listclosecash = ref([]);
const total = ref(0);
const remark = ref('');
const uid_edit = ref('');
const closecash_detail = ref({
  uid: '',
  cashAccUid: '',
  closingDateTime: new Date(),
  totalBillCount: 0,
  totalAmount: 0,
  remark: '',
  fundStatus: '',
  approveBy: '0',
  approveDate: null,
  version: '',
  cashDetails: [],
  refDocUid: '0'
}
);
const totalBillCount = ref('');
const totalAmount = ref('');
const listcashob = ref([]);


const thumb_name = ref("");
const thumb = ref(null);
const thumb_id = ref('');
const loading = ref(false);
const images = ref([])


const shopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        await getOpenBalanceToday()
        await loadCashAccDataApi()
        await loadBanknote()
        await loadDataApi()

      }
    }
  }
}

shopInfoApi()



const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listcashAcc.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listcashAcc.value.push(listd[i])

          }
        }
      }
    }
  }
}


const loadBanknote = async (val) => {
  cashDetails.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: '',
    multiply: '',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  // const response = await store.dispatch("banknoteListFetch", payload);
  const response = await storeb.banknoteListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        listd.map(function (x) {
          x.billCount = "0"
          x.amount = ""
          return x
        });
        cashDetails.value = listd;
        // console.log("loadBanknote : " + JSON.stringify(listbanknote.value));
      }
    }
  }
};


const loadDataApi = async (val) => {
  // listclosecash.value = []
  let data = "";
  let sd = ''
  let ed = ''
  // var date = new Date(start_date.value);
  // date.setDate(date.getDate() - 1);
  // sd = moment(new Date(date)).format('YYYY-MM-DD')
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  if (currentPage.value !== 1) {
    if (listclosecash.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }


  if (cashacc_search.value !== '' && val !== 'del') {
    currentPage.value = 1
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    cashAccUid: cashacc_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecc.closeCashListFetch(payload);
  // console.log("listclosecash : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listclosecash.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function updatePagination() {
  listclosecash.value = []
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')
  // sd = moment(new Date(start_date.value)).format('YYYY-MM-DD') + "T00:00:00"
  // ed = moment(new Date(end_date.value)).format('YYYY-MM-DD') + "T23:59:59"

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    cashAccUid: cashacc_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecc.closeCashListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listclosecash.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function addFile(e) {
  thumb.value = null
  thumb_name.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
    }
  }

}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});


async function addCloseCash() {
  let payload = ""
  payload = {
    cashAccUid: cashAccUid.value,
    remark: remark.value.trim(),
    cashDetails: cashDetails.value,
    closingDateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    refDocUid: '0'
  }
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }
  // console.log('addCloseCash p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashAddFetch(payload);
  // console.log('addCloseCash r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateCloseCash() {
  let payload = ""
  payload = {
    uid: uid_edit.value,
    cashAccUid: cashAccUid.value,
    closingDateTime: closecash_detail.value.closingDateTime,
    totalBillCount: totalBillCount.value,
    totalAmount: total.value,
    remark: remark.value.trim(),
    fundStatus: closecash_detail.value.fundStatus,
    approveBy: closecash_detail.value.approveBy,
    approveDate: closecash_detail.value.approveDate,
    version: closecash_detail.value.version,
    cashDetails: cashDetails.value,
    refDocUid: closecash_detail.value.refDocUid
  }
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }
  // console.log('updateCloseCash p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashUpdateFetch(payload);
  // console.log('updateCloseCash r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deleteCloseCash(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('delete p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashDeleteFetch(payload);
  // console.log('delete r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function approveCloseCash(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('approve p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashApproveFetch(payload);
  // console.log('approve r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function closeCashInfo(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('info p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashInfoFetch(payload);
  // console.log('info r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        closecash_detail.value = response.data.data
        if (closecash_detail.value !== null) {
          cashAccUid.value = closecash_detail.value.cashAccUid
          remark.value = closecash_detail.value.remark
          cashDetails.value = closecash_detail.value.cashDetails
          uid_edit.value = closecash_detail.value.uid
          totalBillCount.value = closecash_detail.value.totalBillCount
          totalAmount.value = closecash_detail.value.totalAmount
          total.value = totalAmount.value
          await setsecondTab()
        }
      } else {
        swal("ดึงข้อมูลไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function loadBalanceInfo() {
  loadBanknote()
  if (listcashAcc.value.length > 0) {
    for (let i = 0; i < listcashAcc.value.length; i++) {
      await getcloseCashOpenBalance(listcashAcc.value[i].uid)
    }
    start_date.value = new Date()
    end_date.value = new Date()
    cashacc_search.value = ''
    getOpenBalanceToday()
    loadDataApi('update')
  }

}


async function getcloseCashOpenBalance(uid) {
  let payload = ""
  payload = {
    cashAccUid: uid,
    closingDateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
  }
  // console.log('ob p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashOpenBalanceFetch(payload);
  // console.log('closeCashOpenBalance r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        await addCloseCashOpenshop(uid)
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}


async function addCloseCashOpenshop(uid) {
  let payload = ""
  payload = {
    cashAccUid: uid,
    cashDetails: cashDetails.value,
    closingDateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
  }
  // console.log('addCloseCash p : ' + JSON.stringify(payload));
  const response = await storecc.closeCashNewOpenshopFetch(payload);
  // console.log('addCloseCashOpenshop r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // loadDataApi('update')
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function getOpenBalanceToday() {
  listcashob.value = []
  let list = []
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date()).format('YYYY-MM-DD')
  ed = moment(new Date()).format('YYYY-MM-DD')

  data = {
    index: 0,
    size: 100,
    start: sd,
    end: ed,
    cashAccUid: ''
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecc.closeCashListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        let ld = list.filter((c) => c.remark === 'Open Account Balance' || c.remark === 'Open the Days');
        listcashob.value = ld
      }
    }
  }

}

async function addfileApi() {
  let data = "";
  data = {
    name: thumb_name.value,
    data: thumb.value,
  };

  const payload = data;
  const response = await storedoc.addFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function getFileRef(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.refDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    }
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}




function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}


async function onClickTab(val) {
  if (val === '1') {
    if (end_date.value >= start_date.value) {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
  }
}

async function backfirstTab() {
  clearItem()
  await loadBanknote()
  await onClickTab('1')
}


async function setfirstTab() {
  if (document.getElementById("second-cashDrawer")) {
    document.getElementById("second-cashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("secondCashDrawer")) {
    document.getElementById("secondCashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.add('show', 'active')
  }
}


async function setsecondTab() {
  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.remove('show', 'active')
  }
  if (document.getElementById("second-cashDrawer")) {
    document.getElementById("second-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("secondCashDrawer")) {
    document.getElementById("secondCashDrawer").classList.add('show', 'active')
  }


}


function filterCashacc(str) {
  let name = listcashAcc.value.filter((c) => c.uid === str);
  return name[0].name;
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function clearItem() {
  thumb.value = null
  cashAccUid.value = ''
  remark.value = ''
  cashDetails.value = []
  uid_edit.value = ''
  totalBillCount.value = ''
  totalAmount.value = ''
  total.value = ''
  closecash_detail.value = {
    uid: '',
    cashAccUid: '',
    closingDateTime: new Date(),
    totalBillCount: 0,
    totalAmount: 0,
    remark: '',
    fundStatus: '',
    approveBy: '0',
    approveDate: null,
    version: '',
    cashDetails: [],
    refDocUid: '0',
  }
  document.getElementById("upload").value = null;
}

async function confirmNewRecord() {
  swal({
    title: "บันทึกธุรกรรม",
    text: "คุณต้องการบันทึกธุรกรรมนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (uid_edit.value === '') {
        addCloseCash()
      } else {
        updateCloseCash()
      }

    }
  });
}


async function onApprove(item) {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการอนุมัติรายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== '') {
        approveCloseCash(item.uid)
      }
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCloseCash(item.uid)
      }
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      if (item.uid !== '') {
        closeCashInfo(item.uid)
      }
    }
  })
}

onMounted(() => {

  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.add('show', 'active')
  }
});
</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
