<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="bmTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" aria-selected="true" @click="onClickTab('1')">
              {{ $t("apps.ghistory") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="tab2-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab"
              aria-controls="tab2" aria-selected="false" @click="onClickTab('2')">
              {{ $t("apps.gticketchain") }}
            </button>
          </li>
        </ul>

        <div id="bmTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("apps.ghistory") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">

                  <div class="col-lg-4  mt-0">
                    <fieldset>
                      <div class="item">
                        <material-checkbox v-model="time" :checked="time" name="autoSelect" @change="onserachDate">
                          <label class="form-label m-0">{{ $t("content.sdate") }}</label>
                        </material-checkbox>
                        <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                          :max-date="new Date()" :enable-time-picker="true" month-name-format="long" :clearable="false"
                          fluid :disabled="!time" :text-input="textInputOptions" />
                      </div>
                    </fieldset>
                  </div>


                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="true" month-name-format="long" :clearable="false"
                      fluid :disabled="!time" :text-input="textInputOptions" />
                  </div>

                  <div class="col-lg-4 mt-2">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus"
                        :disabled="serial_search !== ''">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" :disabled="serial_search !== ''" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("apps.gtransaction") }}</label>
                    <select class="form-select" v-model="tx_search" :disabled="serial_search !== ''" @change="changeTx">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="NORMAL">{{ $t("content.cintrate1") }}</option>
                      <option value="MIGRATION">{{ $t("content.transmig") }}</option>
                    </select>
                  </div>

                  <div v-if="tx_search === 'MIGRATION'" class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.amnbiz") }}</label>
                    <select class="form-select" v-model="type_search" :disabled="serial_search !== ''">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="INTEREST">{{ $t("content.actpawn2") }}</option>
                      <option value="INC_PRINCIPLE">{{ $t("content.actpawn3") }}</option>
                      <option value="DEC_PRINCIPLE">{{ $t("content.actpawn4") }}</option>
                    </select>
                  </div>

                  <div v-else class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.amnbiz") }}</label>
                    <select class="form-select" v-model="type_search" :disabled="serial_search !== ''">
                      <option value=''>{{ $t('content.optall') }}</option>
                      <option value="PAWN">{{ $t("content.actpawn1") }}</option>
                      <option value="INTEREST">{{ $t("content.actpawn2") }}</option>
                      <option value="INC_PRINCIPLE">{{ $t("content.actpawn3") }}</option>
                      <option value="DEC_PRINCIPLE">{{ $t("content.actpawn4") }}</option>
                      <option value="REDEEM">{{ $t("content.actpawn5") }}</option>
                    </select>
                  </div>


                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.status") }}</label>
                    <select class="form-select" v-model="search_status">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option value="FINISH" selected>{{ $t("content.status_finish") }}</option>
                      <option value="FREEZE" selected>{{ $t("content.status_freeze") }}</option>
                      <option value="TERMINATE" selected>{{ $t("content.status_termi") }}</option>
                    </select>
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.serial") }}</label>
                    <material-input type="text" v-model="serial_search" @input="resetinput" />
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadDataHistoryApi" :disabled="(!time && cusName_search === '' && tk_search === '' && serial_search === '') || monthDiff()
                          || (time && start_date > end_date)">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ search }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <label class="form-label m-0 me-2">{{ $t("content.expfile") }} :</label>
                    <div>
                      <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                        class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                        <i class="material-icons material-symbols-outlined">{{ i.ic }}</i>
                        {{ i.nm }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.amnticketold") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.allweight") }}</th>
                          <th>{{ $t("content.allqty") }}</th>
                          <th>{{ $t("content.amnpawnvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>
                            {{ $t("content.actpawn3") }} /
                            {{ $t("content.actpawn4") }}
                          </th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.assessor") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listhistory" :key="index"
                          :class="row.status !== 'TERMINATE' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.prevRefNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ทำแทน)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ทำแทน)
                              </span>
                            </span>
                            <span v-else-if="row.shopRepresent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ออนไลน์)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ออนไลน์)
                              </span>
                            </span>
                            <span v-else-if="row.changeName">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType)
                                }},เปลี่ยนชื่อ)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (เปลี่ยนชื่อ)
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }})
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.type !== 'REDEEM'">
                              {{ formatNumberDecimalString(row.newPawnValue) }}
                            </span>
                            <span v-else>
                              {{ formatNumberDecimalString(row.pawnValue) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.roundInterest) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.diffValue) }}</td>
                          <td class="text-sm font-weight-normal">

                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.shopManagerName }}</td>
                          <td class=" text-sm font-weight-normal text-center">
                            {{ checkProcess(row.status) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" :title="chain"
                              @click="openListChain(row)">
                              <i class="material-icons material-symbols-outlined">arrow_forward</i>
                            </material-button>
                            <material-button color="dark" variant="outline" class="rounded-circle"
                              :title="t('buttons.printp')" @click="ticketGen(row)"
                              :disabled="row.type === 'REDEEM'
                                || (row.status === 'TERMINATE' && row.refNumber === '') || format_date(row.createDate) !== format_date(new Date())">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                            <material-button color="secondary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('buttons.printpb')"
                              @click="backCoverGen(row)" :disabled="row.type === 'PAWN' || row.status === 'TERMINATE'">
                              <i class="material-icons material-symbols-outlined">description</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnticketmanage')"
                              :disabled="row.type !== 'PAWN' || row.status === 'TERMINATE' || format_date(row.createDate) !== format_date(new Date())"
                              @click="openEdit(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="primary" class="float-lg-start float-sm-end mb-0"
                              data-bs-toggle="modal" data-bs-target="#newPawnListModal" id="newPawnModal"
                              v-show="false" />
                            <material-button color="dark" variant="outline" class="rounded-circle"
                              @click="onattachmenDialog(row)"
                              :disabled="row.type === 'REDEEM' || row.status === 'TERMINATE'"
                              :title="t('content.tupload')">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                            <material-button data-bs-toggle="modal" data-bs-target="#attachmentsModal" v-show="false"
                              id="attachmenDialog">

                            </material-button>

                            <!-- <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('buttons.canct')">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button> -->
                          </td>
                        </tr>
                        <tr v-if="listhistory.length === 0">
                          <td colspan="14" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="group-sub-header">
                          <td class="text-lg font-weight-bold text-end text-center" colspan="6">
                            <span v-if="listhistory.length > 0">
                              <b>{{ $t("content.gtotal") }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <span v-if="listhistory.length > 0">
                              <b>{{ formatNumberDecimalString(totalWeight) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <span v-if="listhistory.length > 0">
                              <b>{{ formatNumberString(totalQuantity) }}</b>
                            </span>
                          </td>

                          <td class="text-lg font-weight-bold">
                            <span v-if="listhistory.length > 0">
                              <b>{{ formatNumberDecimalString(totalPawnValue) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <span v-if="listhistory.length > 0">
                              <b>{{ formatNumberDecimalString(totalInterest) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold" colspan="4"></td>
                          <td style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listhistory.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab2" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 style="display: inline-block; width: auto">
                    {{ $t("apps.gticketchain") }}
                  </h5>
                  <div class="d-flex justify-content-end align-items-center">
                    <label class="form-label m-0 me-2">{{ $t("content.expfile") }} :</label>
                    <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                      class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                      <i class="material-icons material-symbols-outlined">{{ i.ic }}</i>
                      {{ i.nm }}
                    </material-button>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.trans") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.allweight") }}</th>
                          <th>{{ $t("content.allqty") }}</th>
                          <th>{{ $t("content.allprice") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listticketchain" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPageT - 1) * perPageT +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ทำแทน)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ทำแทน)
                              </span>
                            </span>
                            <span v-else-if="row.shopRepresent">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }},ออนไลน์)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (ออนไลน์)
                              </span>
                            </span>
                            <span v-else-if="row.changeName">
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType)
                                }},เปลี่ยนชื่อ)
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }} (เปลี่ยนชื่อ)
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.migration">
                                {{ checkTransaction(row.type) }} ({{ checkTransaction(row.migrationType) }})
                              </span>
                              <span v-else>
                                {{ checkTransaction(row.type) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listticketchain.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="10" style="text-align: right">Total Rows : {{ totalRows_sT }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listticketchain.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageT" :total-items="totalRowsT"
                          :items-per-page="perPageT" @click="onClickHandlerT" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <!-- Search Customer Dialog -->
          <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="preview-table">
                    <section class="panel">
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                            <th>#</th>
                            <th>{{ $t("content.cnameth") }}</th>
                            <th>{{ $t("content.cnameen") }}</th>
                            <th>{{ $t("content.docno") }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row-search">
                            <td></td>
                            <td>
                              <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                            </td>
                            <td>
                              <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                            </td>
                            <td>
                              <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                            </td>
                            <td>
                              <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                                :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                                @click="loadCustomerAfListApi">
                                <i class="material-icons material-symbols-outlined">search</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                            <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                            <td>{{ row.nameTh }}</td>
                            <td>{{ row.nameEn }}</td>
                            <td colspan="2">{{ row.docNumber }}</td>
                          </tr>
                          <tr v-if="customerlist.length === 0">
                            <td colspan="5" class="text-sm font-weight-normal">
                              <p style="text-align: center">No Record Found</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                </div>

                <div class="modal-footer">
                  <pre></pre>
                  <div style="display: flex; justify-content: flex-end">
                    <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                            :items-per-page="perPageC" @click="onClickHandlerC" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <!-- Detail Modal -->
          <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">

                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                        <div class="carousel-indicators" id="demo1">
                        </div>
                        <div class="carousel-inner">
                          <ul className="list-unstyled">
                            <li v-for="(item, index) in images.length" :key="index">
                              <span v-if="index === 0">
                                <div class="carousel-item active">
                                  <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                    class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                    message-pos="top" />
                                </div>
                              </span>
                              <span v-else>
                                <div class="carousel-item">
                                  <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                    class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                    message-pos="top" />
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                          data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                          data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-2">
                      <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                        <div>
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>

                                <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                  <span v-if="workcaseDetail.tkRefNumber !== ''">
                                    {{ workcaseDetail.tkRefNumber }} &nbsp;
                                    <span @click='copyBtn()' style="cursor: pointer">
                                      <i class="inline-icon material-icons">content_copy</i>
                                    </span>
                                  </span>
                                  <span v-else>-</span>
                                </td>
                                <td class="fs-14 text-bold">
                                </td>

                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                  {{ checkTransaction(workcaseDetail.transactionType) }}
                                </td>

                                <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                  <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                    {{ tk_status }}
                                  </span>
                                  <span
                                    v-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                    style="color:red;">
                                    {{ tk_status }}
                                  </span>
                                  <span v-else>
                                    {{ tk_status }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount === 0">
                                <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                  format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.amnduedate") }} :
                                  <span style="color:red;">
                                    {{ format_date(workcaseDetail.dueDate) }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                  format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2">
                                  {{ $t("content.amnduedate") }} :
                                  <span style="color:red;">
                                    {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                      format_date(workcaseDetail.postponeDate) }})
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.postponeCount !== 0">
                                <td class="fs-14 text-bold" colspan="2">
                                  {{ $t("content.deferpast") }} :
                                  <span @click='NewTab()' style="text-decoration: underline;color:red; cursor: pointer">
                                    {{ workcaseDetail.postponeCount }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.monthlyprof") }} : {{
                                    formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                                </td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.currentprof") }} :
                                  <span style="color:red;">
                                    {{ formatNumberDecimalString(int_cal) }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.prointerest") }} :
                                  <span
                                    v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                    -
                                  </span>
                                  <span style="color:blue;" v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                  </span>
                                </td>
                                <td class="fs-14 text-bold">
                                  {{ $t("content.periodpromotion") }} :
                                  <span v-if="workcaseDetail.promotionDuration === 0">
                                    -
                                  </span>
                                  <span style="color:blue;" v-else>
                                    {{ workcaseDetail.promotionDuration }} เดือน
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                  {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.pawnvalpv") }} :
                                  <span v-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <!-- <span v-if="workcaseDetail.transactionType === 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <span v-else-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span> -->
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.actpawn3") }}/{{ $t("content.actpawn4") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  <!-- <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span> -->
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                  {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                  <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                  </span>
                                  <span v-else style="color:red;">
                                    มี ({{ workcaseDetail.lostCount }})
                                  </span>
                                </td>
                                <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                  <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                  </span>
                                  <span v-else style="color:red;">
                                    {{ workcaseDetail.lostRefNumber }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.represent || workcaseDetail.shopRepresent">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                  <span style="color:red;">
                                    {{ workcaseDetail.workItems[0].customerName }}
                                  </span>
                                </td>
                              </tr>
                              <tr v-if="workcaseDetail.changeName">
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                  <span style="color:red;">
                                    {{ workcaseDetail.workItems[0].tkCustomerName }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                  <span v-if="location !== ''">
                                    {{ location.fullName }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.stockno") }} :
                                  <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                    {{ workcaseDetail.ticket.stockNumber }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                              <tr>
                                <td class="fs-14 text-bold" colspan="2"> {{ $t("content.migraref") }} :
                                  <span v-if="workcaseDetail.migrateRefNumber !== null">
                                    {{ workcaseDetail.migrateRefNumber }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>

                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <span v-if="listinfo.length !== 0">
                            <table class="table table-sm table-borderless">
                              <tbody>
                                <tr>
                                  <td class="fs-14 text-bold">
                                    <span style="text-decoration:underline;">
                                      {{ $t("content.amnassetdetail") }}
                                    </span>
                                  </td>
                                </tr>

                                <tr v-for="(row, index) in listinfo" :key="index">
                                  <td class="fs-14 text-bold">
                                    {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                          </span>
                          <span v-else>
                            <tr>
                              <td class="fs-14 text-bold">
                                <span style="text-decoration:underline;">
                                  {{ $t("content.amnassetdetail") }}
                                </span>
                                : -
                              </td>

                            </tr>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="paymentE !== ''" />
                  <div class="col-12 mt-2" v-if="paymentE !== ''">
                    <div class="fs-14 text-bold">
                      <span style="text-decoration:underline;">
                        {{ $t("content.amnfinanceinfo") }}
                      </span>
                    </div>
                    <pre></pre>
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t("content.ticketno") }}</th>
                              <th>{{ $t("content.amnbiz") }}</th>
                              <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnincome") }}
                                </span>
                              </th>
                              <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnoutcome") }}
                                </span>
                              </th>
                              <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.fee") }}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th colspan="3"></th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncreditcard") }}
                                </span>

                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amncheque") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amncash") }}
                                </span>
                              </th>
                              <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amnbanktransfer") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amncreditcard") }}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th colspan="3"></th>
                              <th style="background-color:#dfffdd;"></th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#dfffdd;">
                                <span style="color:black;">
                                  QR Code
                                </span>
                              </th>
                              <th colspan="2" style="background-color:#dfffdd;"></th>
                              <th style="background-color:#fecac7;"></th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amntransfer_promt_mobile") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#fecac7;">
                                <span style="color:black;">
                                  {{ $t("content.amntransfer_promt_taxid") }}
                                </span>
                              </th>
                              <th style="background-color:#CFEBFD;"></th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  {{ $t("content.amnbankacc") }}
                                </span>
                              </th>
                              <th class="align-top text-center" style="background-color:#CFEBFD;">
                                <span style="color:black;">
                                  QR Code
                                </span>
                              </th>
                              <th style="background-color:#CFEBFD;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, index) in paymentE.workCases" :key="index">
                              <template v-if="row.ticket !== null">
                                <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                                </td>
                                <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                                <td class="text-sm font-weight-normal text-center">{{
                                  checkTransaction(row.transactionType) }}</td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[0].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[1].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[6].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[6].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[2].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[2].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[3].receive > 0" style="color:green;">
                                    {{ formatNumberDecimalString(payment_l[3].receive) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[0].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[1].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[4].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[4].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[5].pay > 0" style="color:red;">
                                    {{ formatNumberDecimalString(payment_l[5].pay) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[0].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[1].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[6].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[6].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                                <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                  :rowspan="paymentE.workCases.length">
                                  <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                    {{ formatNumberDecimalString(payment_l[2].fee) }}
                                  </span>
                                  <span v-else>
                                    -
                                  </span>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Add New List in Pawn Ticket Dialog -->
          <div id="newPawnListModal" class="modal fade" aria-labelledby="newPawnListModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ $t("content.amnsplitlist") }} </h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">
                  <!-- <div id="custom-target"></div> -->
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-4">
                        <label class="form-label ms-0">{{
                          $t("content.amnassetgroup")
                        }}</label>
                        <select v-model="collect_detail.mainCatUid" class="form-select" disabled>
                          <option value="0" selected disabled>{{ $t("content.select") }}</option>
                          <option v-for="(item, index) in listprint" :value="item.uid" :key="index">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-lg-8">
                        <label class="form-label ms-0">
                          {{ $t("content.amnassetgroup") }}
                          <span v-if="typesNew.length > 0">
                            ({{ textt }})
                          </span>
                        </label>
                        <div ref="parentRef" class="cs-auto-input">
                          <input id="inp" v-model="filterText" ref="input" placeholder="ค้นหาประเภททรัพย์..."
                            class="form-control auto-input-search" list="assetOptions" @input="newformatinput"
                            autocomplete="off" type="search" :disabled="collect_detail.uid === ''">
                          <ul id="myList" v-if="dataList.length > 0 && filterText.length > 0" ref="list"
                            class="data-list" :style="`width: ${childWidth}px !important;`" @scroll="handleScroll">
                            <li v-for="(item, index) in dataList" :key="index" @click="selection(item)">
                              <a>{{ item.datailt }}</a>
                            </li>
                            <li v-if="loading_l">Loading...</li>
                            <p v-if="dataList.length" id="load-more" :class="loadMoreStatus"></p>
                          </ul>
                        </div>
                      </div>

                      <div class="row flex flex-wrap p-fluid">

                        <div class="flex-auto col-lg-4">
                          <label class="form-label ms-0">{{ $t("content.amnqtyunittick") }} </label>
                          <InputNumber v-model="collect_detail.quantity" autocomplete="off" fluid disabled />
                        </div>

                        <div class="flex-auto col-lg-2">
                          <label class="form-label ms-0">{{ $t("content.munit") }}</label>
                          <select v-model="collect_detail.unitUid" class="form-select" @change="setRemark"
                            :disabled="collect_detail.uid === ''">
                            <option value="0" selected disabled>{{ $t("content.select") }}</option>
                            <option v-for="(item, index) in listunit" :value="item.uid" :key="index">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>

                        <div class="flex-auto col-lg-6">
                          <label class="form-label ms-0">Serial Number</label>
                          <material-input id="serialPawn" v-model="collect_detail.serialNumber" autocomplete="off"
                            @update:modelValue="setRemark()" :disabled="collect_detail.uid === ''" />
                        </div>

                      </div>

                      <div class="row flex flex-wrap p-fluid">
                        <div class="flex-auto col-lg-4">
                          <label class="form-label ms-0">{{ $t("content.amnhdweightall") }}</label>
                          <InputNumber v-model="collect_detail.weight" :minFractionDigits="0" :maxFractionDigits="2"
                            autocomplete="off" fluid disabled />
                        </div>


                        <div class="col-lg-4">
                          <label class="flex-auto form-label ms-0">{{ $t("content.amnanalysval") }}</label>
                          <InputNumber v-model="collect_detail.newPawnValue" :minFractionDigits="0"
                            :maxFractionDigits="2" autocomplete="off" fluid disabled />

                        </div>

                        <div class="flex-auto col-lg-4">
                          <label class="form-label ms-0">{{ $t("content.amnmaxval") }}</label>

                          <InputNumber v-model="collect_detail.fullValue" :minFractionDigits="0" :maxFractionDigits="2"
                            autocomplete="off" fluid disabled />
                        </div>
                      </div>

                      <div class="col-12 mt-2">
                        <div class="d-flex align-items-center mb-1">
                          <label class="form-label ms-0 mb-0 me-3">
                            {{ $t("content.amnoldmessage") }}
                          </label>
                        </div>
                        <Textarea rows="2" v-model="oldremark" class="w-100" disabled />
                      </div>

                      <div class="col-12 mt-3">
                        <div class="d-flex align-items-center mb-1">
                          <label class="form-label ms-0 mb-0 me-3">
                            {{ $t("content.orderinfo") }}
                          </label>
                        </div>
                        <Panel>
                          <div class="table-responsive">
                            <table class="table table-sm table-hover">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>{{ $t("content.amnassetgroup") }}</th>
                                  <th>{{ $t("content.amnweightval") }}</th>
                                  <th>{{ $t("content.amnqtyunit") }}</th>
                                  <th>{{ $t("content.amnanalysval") }}</th>
                                  <th>{{ $t("content.amnmaxval") }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(row, index) in listinfo" :key="index">
                                  <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                                  <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                                  <td class="text-sm font-weight-normal">
                                    <span style="text-decoration:underline;color:red;">
                                      {{ formatNumberDecimalString(row.weight) }}
                                    </span>
                                  </td>
                                  <td class="text-sm font-weight-normal">
                                    <span style="text-decoration:underline;color:red;">
                                      {{ formatNumberString(row.quantity) }}
                                    </span>
                                  </td>
                                  <td class="text-sm font-weight-normal">
                                    <span style="text-decoration:underline;color:red;">
                                      {{ formatNumberDecimalString(row.pawnValue) }}
                                    </span>
                                  </td>
                                  <td class="text-sm font-weight-normal">
                                    <span style="text-decoration:underline;color:red;">
                                      {{ formatNumberDecimalString(row.fullValue) }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Panel>
                      </div>

                      <div class="flex flex-wrap p-fluid mt-2">
                        <div class="flex-auto col-lg-4">
                          <label class="flex-auto form-label ms-0">
                            {{ $t("content.ticketdetail") }}
                          </label>

                          <small class="form-label ms-2" style="color:red;">
                            <span @click="selectFlaw()" style="text-decoration: underline;color:red; cursor: pointer">
                              {{ $t("content.defect") }}
                            </span>
                          </small>
                        </div>
                        <div class="card mb-3" v-if="openFlaw">
                          <Panel>
                            <template #header>
                              <span> <label class="flex-auto form-label ms-0">{{ $t("content.select") }} {{
                                $t("content.defect") }}</label></span>
                            </template>
                            <template #icons>
                              <Button severity="secondary" rounded text class="btnClose" @click="selectFlaw()">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </Button>
                            </template>
                            <template #footer>
                              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button class="btn btn-primary btn-sm" type="button" @click="flawSelection()"> {{ save
                                  }}</button>
                              </div>
                            </template>
                            <div class="grid-container">
                              <div v-for="option in listflaw" :key="option" class="d-inline-block mx-4">
                                <input type="checkbox" v-model="option.chk" :check="option.chk" /><label
                                  class="flex-auto form-label ms-2">
                                  {{ option.name }}</label>
                              </div>
                            </div>

                          </Panel>
                        </div>
                        <material-input v-model="collect_detail.remark" autocomplete="off" class="w-100"
                          :disabled="collect_detail.uid === ''" />
                      </div>
                    </div>
                  </fieldset>
                  <pre></pre>
                  <div class="row mt-4 no-gutters">
                    <div class="col-6 d-flex justify-content-end align-items-center">
                    </div>

                    <div class="col-6 d-flex justify-content-end align-items-center">
                      <button type="button" class="btn btn-primary" :disabled="(collect_detail.mainCatUid === '' || collect_detail.mainCatUid === '0')
                        || filterText === '' || collect_detail.remark.trim() === '' || collect_detail.unitUid === '0'"
                        @click="update">
                        {{ save }}
                      </button>
                    </div>
                  </div>
                  <pre></pre>

                  <div class="me-2">
                    <h6>
                      <b>{{ $t("content.amnassetdatas") }}</b>
                    </h6>
                  </div>

                  <div class="row mt-4">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>

                            <th>#</th>
                            <th>{{ $t("content.amndatetime") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th>{{ $t("content.amnassetgroup") }}</th>
                            <th>{{ $t("content.amnweightval") }}</th>
                            <th>{{ $t("content.amnqtyunit") }}</th>
                            <th>{{ $t("content.amnanalysval") }}</th>
                            <th>{{ $t("content.amnmaxval") }}</th>

                            <th>{{ $t("content.act") }}</th>
                          </tr>
                        </thead>

                        <tbody v-for="(row, index) in listQ02D" :key="index" :data-group-index="index">
                          <tr class="group-id-ticket" :class="row.st == 'finished' ? '' : 'bg-working'">
                            <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                            <td class="text-sm font-weight-normal">{{ format_date(listQ02E.createDate) }}</td>
                            <td class="text-sm font-weight-normal">{{ checkTransaction(listQ02E.type) }}</td>
                            <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                            <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight)
                              }}
                            </td>
                            <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                            <td class="text-sm font-weight-normal">
                              <span v-if="listQ02E.type !== 'INC_PRINCIPLE' && listQ02E.type !== 'DEC_PRINCIPLE'">
                                {{ formatNumberDecimalString(row.newPawnValue) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.tmpNewPawnValue) }}
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">{{
                              formatNumberDecimalString(row.fullValue) }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="edit"
                                @click="setItemDetail(row)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <!-- <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="cancel" @click="onDelete(row)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button> -->
                            </td>
                          </tr>
                          <tr v-for="(j, set1) in row.remarkL" :key="set1">
                            <td class="text-sm font-weight-normal text-end" colspan="3">
                              <span style="text-decoration:underline">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="7">
                              <span style="color:red">
                                <b>{{ j.val }}</b>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Add File Dialog -->
          <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 700px">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title"> {{ tk_cusname }}
                    ({{ $t("content.ticketno") }} : {{ tk_ref }}) </h5>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeattach">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-12">
                        {{ $t("content.file") }} {{ preview_list.length }}/5
                      </div>
                      <pre></pre>
                      <div class="col-lg-12">
                        <div class="d-flex align-items-center mb-1">
                        </div>
                        <input type="file" accept="image/*" @change="previewMultiImage" class="form-control-file"
                          id="my-file" :disabled="preview_list.length >= 5" />
                      </div>
                    </div>
                    <pre></pre>
                    <div class="grid-containerP" v-if="preview_list.length">
                      <div v-for="item, index in preview_list" :key="index" class="d-inline-block mx-2">
                        <td>
                          <div class="wrapper">
                            <figure width="100px" height="100px">
                              <img :src="item" class="img img-fluid" />
                            </figure>
                            <button type="button" class="btn myLayer" style="color: red;" :title="t('buttons.dele')"
                              @click="removeImage(item, index)">
                              <i i class="material-icons">close</i>
                            </button>
                          </div>
                        </td>
                      </div>
                    </div>

                  </fieldset>
                </div>

                <div class="modal-footer justify-content-between">
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                    {{ cancel }}
                  </button>
                  <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    :disabled="image_list.length <= 0" @click="uploadImg()">
                    {{ save }}
                  </button>
                </div>
              </div>
            </div>
          </div>


          <!-- Search Sanction Dialog -->
          <div id="searchSanctionModal" class="modal fade" tabindex="-1" aria-labelledby="searchSanctionModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title text-center">
                    <span style="color:red;">
                      {{ $t('content.sanctionmsg') }} {{ $t('content.millegal') }}
                    </span>
                  </h4>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                    <i class="material-icons material-symbols-outlined">close</i>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="preview-table">
                    <section class="panel">
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{{ $t('content.serial') }}</th>
                            <th>{{ $t('content.refer') }}</th>
                            <th>{{ $t('content.docnum') }}</th>
                            <th>{{ $t('content.docdate') }}</th>
                            <th>{{ $t('content.criminal') }}</th>
                            <th>{{ $t('content.agency') }}</th>
                            <th>{{ $t('content.defect') }}</th>
                            <th>{{ $t('content.detail') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in wanninglist" :key="index">
                            <td class="text-sm font-weight-normal">
                              {{ formatNumberString(index + 1) }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.serialNumber }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.reportBy }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.docNumber }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ format_date(row.docDate) }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.caseNumber }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.source }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.flawMark }}
                            </td>
                            <td class="text-sm font-weight-normal">
                              {{ row.remark }}
                            </td>
                          </tr>
                          <tr v-if="wanninglist.length === 0">
                            <td colspan="5" class="text-sm font-weight-normal">
                              <p style="text-align: center">No Record Found</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                </div>
                <div class="modal-footer">
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import Modal from 'bootstrap/js/dist/modal';
import ProgressSpinner from 'primevue/progressspinner';
import { reactive, ref, computed, onMounted, inject, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Textarea from 'primevue/textarea';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";
import pdfMake from "pdfmake/build/pdfmake";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import InputNumber from 'primevue/inputnumber';
import Panel from 'primevue/panel';
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import pdfFonts from '@/assets/custom-fonts.js' // 1. import custom fonts
// pdfMake.vfs = pdfFonts.pdfMake.vfs // 2. set vfs pdf font

// pdfMake.fonts = {
//   THSarabunNew: {
//     normal: 'thsarabunnew-webfont.ttf',
//     bold: 'thsarabunnew_bold-webfont.ttf',
//     italics: 'thsarabunnew_italic-webfont.ttf',
//     bolditalics: 'thsarabunnew_bolditalic-webfont.ttf'
//   },
// }

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"


import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useCategoryStore } from '@/stores/category'
import { useInterestStore } from '@/stores/interest'
import { useLocationStore } from '@/stores/location'
import { useUniteStore } from '@/stores/unit'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useFlawtStore } from '@/stores/flaw'
import { useCollateralStore } from '@/stores/collateral'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useDocumentStore } from '@/stores/document'
import { useTicketImageStore } from '@/stores/ticketimage'
import { useSanctionAssetsStore } from '@/stores/sanctionassets'

const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const storec = useCategoryStore()
const storei = useInterestStore()
const storel = useLocationStore()
const storeu = useUniteStore()
const storep = usePaymentGroupStore()
const storetf = useFlawtStore()
const storecl = useCollateralStore()
const storeca = useCashAccountStore()
const stored = useDocumentStore()
const storeti = useTicketImageStore()
const storeass = useSanctionAssetsStore()


const { t } = useI18n();
const swal = inject("$swal");





const exportFile = reactive([
  {
    cl: "info",
    cs: "me-2",
    ic: "picture_as_pdf",
    nm: "pdf",
    ac: "",
  },
  {
    cl: "info",
    cs: "",
    ic: "table_rows",
    nm: "excel",
    ac: "",
  },
]);


const cancel = computed(() => t("buttons.canc"));
const confirm = computed(() => t("buttons.conf"));
const chain = computed(() => t('buttons.seed') + t("apps.gticketchain"));
const copy = computed(() => t("buttons.copyData"));
const search = computed(() => t("buttons.sear"));
const save = computed(() => t("buttons.save"));

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY HH:mm');
}

const textInputOptions = {
  format: 'dd/MM/yyyy HH:mm'
};



const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageT = ref(1);
const totalRowsT = ref(1);
const perPageT = ref(10);
const totalRows_sT = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const cusUid_search = ref('');
const cusName_search = ref('');
const tk_search = ref('');
const type_search = ref('');
const serial_search = ref('');
const search_status = ref('');
const tx_search = ref('');


const listprint = ref([]);
const listhistory = ref([]);
const listticketchain = ref([]);
const listinfo = ref([]);
const customerlist = ref([])
const info_cat = ref('');
const workcaseDetail = ref(null);
const chain_uid = ref('');
const tk_status = ref('');
const int_cal = ref(0);
const location = ref('');
const paymentE = ref('');
const time = ref(false);
const tk_ref = ref('');
const tk_cusname = ref('');

const images = ref([])
const images_l = ref([])

const shop_detail = ref({})

const payment_l = ref([])

const loading = ref(false);


const listunit = ref([]);
const listflaw = ref([]);

const oldremark = ref("");

const dataList = ref([]);
const loading_l = ref(false);
const showSuggestions = ref(false);
const limit = 10;
const page_s = ref(0);
const page_e = ref(10);
const filterText = ref("");
const filterInput = ref("");
const typesNew = ref([]);
const selected = ref("");
const textt = ref("");
const Listdata = ref([]);
const types = ref([]);
const openFlaw = ref(false);
const uid_edit = ref('');
const listQ02D = ref([]);
const listQ02E = ref([]);
const wanninglist = ref([]);
const tplist = ref([])


const totalQuantity = ref(0);
const totalPawnValue = ref(0);
const totalWeight = ref(0);
const totalInterest = ref(0);



// infinite scroll
var input = (ref < HTMLInputElement) | (null > null);
const parentRef = ref(null);
const list = ref(null);
const parentWidth = ref(0);
const childWidth = ref(0);



const collect_detail = ref({
  uid: '',
  ticketUid: '',
  mainCatUid: 'no',
  mainCatCode: '',
  mainCatName: '',
  categoryUid: '',
  categoryCode: '',
  categoryName: '',
  weight: '',
  pawnValue: '',
  newPawnValue: '',
  tmpPawnValue: '',
  tmpNewPawnValue: '',
  fullValue: '',
  diamondQuantity: '',
  quantity: '',
  unitUid: 'no',
  unitName: '',
  remark: '',
  collateralGroupUid: '',
  version: '',
  serialNumber: '',
});


const thumb_id = ref("");
const thumb = ref(null);
const preview = ref("");
const preview_list = ref([])
const image_list = ref([])
const count_success = ref(0);
const err_msg = ref("");


const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};


const onClickHandlerT = (page) => {
  updatePaginationT()
};

const onClickHandlerC = (page) => {
  updatePaginationC()
};



const NewTab = async () => {
  sessionStorage.removeItem('deferWorkcaseUid');
  sessionStorage.setItem("deferWorkcaseUid", workcaseDetail.value.uid);
  if (sessionStorage.getItem('deferWorkcaseUid') !== null) {
    const win = window.open("#/generals/defer_items", "_blank");
    win.focus();
    sessionStorage.removeItem('deferWorkcaseUid');
  }
}



async function previewMultiImage(event) {
  var input = event.target;
  var count = input.files.length;
  var index = 0;
  if (input.files) {
    while (count--) {
      image_list.value.push(await toBase64(input.files[index]));
      preview_list.value.push(URL.createObjectURL(input.files[index]));
      // preview.value = URL.createObjectURL(e.target.files[0]);
      // var reader = new FileReader();
      // reader.onload = (e) => {
      //   preview_list.value.push(e.target.result);
      // }
      // image_list.value.push(input.files[index]);
      // reader.readAsDataURL(input.files[index]);
      index++;
    }
  }
}

async function removeImage(image, index) {
  preview_list.value.splice(index, 1);
  image_list.value.splice(index, 1);
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});

async function onattachmenDialog(item) {
  thumb.value = null
  preview.value = ''
  preview_list.value = []
  image_list.value = []
  uid_edit.value = ''
  workcaseDetail.value = null
  tk_ref.value = ''
  tk_cusname.value = ''

  document.getElementById("my-file").value = null;
  await getworkCaseInfo(item.uid)
  if (workcaseDetail.value != null) {
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value.ticket))
    uid_edit.value = workcaseDetail.value.ticket.uid
    tk_ref.value = item.refNumber
    if (item.changeName) {
      tk_cusname.value = item.customerName
    } else {
      tk_cusname.value = item.tkCustomerName
    }
    document.getElementById("attachmenDialog").click();
  }
}


async function uploadImg() {
  loading.value = true
  count_success.value = 0
  for (let i = 0; i < image_list.value.length; i++) {
    await addfileApi(image_list.value[i])
  }
  loading.value = false
  document.getElementById("closeattach").click();
  if (count_success.value === 0) {
    swal("บันทึกไม่สำเร็จ", err_msg.value, "error");
  } else {
    swal("บันทึกสำเร็จ", Number(count_success.value) + "/" + image_list.value.length + " ไฟล์", "success");
  }
}


const getshopInfo = async (val) => {
  shop_detail.value = {}
  const response = await storeca.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        shop_detail.value = response.data.data

      }
    }
  }
}

getshopInfo()


const loadDataApi = async () => {

  let data = "";
  data = {
    uid: 0,
  };
  const payload = data;

  const response = await storec.categoryfullNameFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        Listdata.value = response.data.data.list;
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
        if (Listdata.value.length > 0) {
          // console.log('newData : '+JSON.stringify(Listdata.value.length))
          types.value = [];
          checkdataMatch2(Listdata.value);
        }
      }
    }
  }
};





const loadDataUnitApi = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    name: '',
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storeu.unitListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listunit.value = response.data.data.list;
      }
    }
  }


};



function copyBtn() {
  navigator.clipboard.writeText(workcaseDetail.value.tkRefNumber);
}

const onserachDate = () => {
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
};


const selectFlaw = () => {
  // console.log('listflaw : ' + JSON.stringify(listflaw.value))
  // console.log('selectFlaw')
  if (listflaw.value.length > 0) {
    if (openFlaw.value) {
      openFlaw.value = false
      if (listflaw.value.length > 0) {
        for (let i = 0; i < listflaw.value.length; i++) {
          listflaw.value[i].chk = false
        }
      }
    } else {
      openFlaw.value = true
    }
    // document.getElementById("defectsBtn").click();
    // console.log('listflaw : ' + JSON.stringify(listflaw.value))

  }
}

const flawSelection = () => {
  let fdt = ''
  if (listflaw.value.length > 0) {
    for (let i = 0; i < listflaw.value.length; i++) {
      if (listflaw.value[i].chk) {
        // console.log('flawSelection : ' + JSON.stringify(listflaw.value[i]))
        if (fdt === '') {
          fdt = listflaw.value[i].name
        } else {
          fdt = fdt + ' ' + listflaw.value[i].name
        }
      }
    }
    setRematkDetail()
    if (fdt !== '') {
      if (collect_detail.value.remark !== '') {
        collect_detail.value.remark = collect_detail.value.remark + ' ' + fdt
      } else {
        collect_detail.value.remark = fdt
      }
      if (collect_detail.value.serialNumber !== '') {
        setSerialDetail()
      }
    }
    selectFlaw()
  }

}

const setRemark = () => {
  setRematkDetail()
  if (collect_detail.value.serialNumber !== '') {
    setSerialDetail()
  }

}


const setRematkDetail = () => {
  let dt = ''
  // console.log("mainCatUid : " + collect_detail.value.mainCatUid);
  if (collect_detail.value.mainCatUid !== 'no') {
    let ld = listprint.value.filter((c) => c.uid === collect_detail.value.mainCatUid);

    if (ld.length > 0) {
      // console.log("ld : " + JSON.stringify(ld[0]));
      // let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf(selected.value.t1.toLowerCase()) >= 0);
      if (ld[0].name !== 'อื่นๆ') {
        if (selected.value === '') {
          let sl = types.value.filter((c) => c.fullName.toLowerCase().indexOf(filterText.value.trim().toLowerCase()) >= 0);
          if (sl.length > 0) {
            selected.value = sl[0]
          }
        }
        // console.log("ld : " + JSON.stringify(ld[0]));
        if (selected.value !== '') {
          if (ld[0].l2Print) {
            if (selected.value.t2 !== '') {
              dt = selected.value.t2
            }

          }
          if (ld[0].l3Print) {
            if (selected.value.t3 !== '') {
              if (dt === '') {
                dt = selected.value.t3
              } else {
                dt = dt + ' ' + selected.value.t3
              }
            }

          }
          if (ld[0].l4Print) {
            if (selected.value.t4 !== '') {
              if (dt === '') {
                dt = selected.value.t4
              } else {
                dt = dt + ' ' + selected.value.t4
              }
            }

          }
          if (ld[0].l5Print) {
            if (selected.value.t5 !== '') {
              if (dt === '') {
                dt = selected.value.t5
              } else {
                dt = dt + ' ' + selected.value.t5
              }
            }

          }
          if (ld[0].l6Print) {
            if (selected.value.t6 !== '') {
              if (dt === '') {
                dt = selected.value.t6
              } else {
                dt = dt + ' ' + selected.value.t6
              }
            }
          }
        }

      } else {
        dt = filterText.value.trim()
      }
      // console.log("dt : " + dt)
    }
    ;
  }



  if (collect_detail.value.quantity !== '0' && collect_detail.value.quantity !== '' && parseFloat(collect_detail.value.quantity) > 0) {
    if (dt === '') {
      dt = collect_detail.value.quantity
    } else {
      dt = dt + ' ' + collect_detail.value.quantity
    }

    if (collect_detail.value.unitUid !== '' && collect_detail.value.unitUid !== '0') {

      if (dt === '') {
        dt = filterUnit(collect_detail.value.unitUid)
      } else {
        dt = dt + ' ' + filterUnit(collect_detail.value.unitUid)
      }
    }
  }
  if (collect_detail.value.weight !== '' && collect_detail.value.weight !== '0' && parseFloat(collect_detail.value.weight) > 0) {
    let weight = collect_detail.value.weight
    if (dt === '') {
      dt = '(' + formatNumberDecimalString(Number(weight)) + ' กรัม)'
    } else {
      dt = dt + ' (' + formatNumberDecimalString(Number(weight)) + ' กรัม)'
    }
  }

  // if (collect_detail.value.serialNumber !== '') {

  //   if (dt === '') {
  //     dt = collect_detail.value.serialNumber.trim()
  //   } else {
  //     dt = dt + ' ' + collect_detail.value.serialNumber.trim()
  //   }
  // }

  collect_detail.value.remark = dt

}


const setSerialDetail = () => {
  if (collect_detail.value.remark === '') {
    collect_detail.value.remark = '(' + collect_detail.value.serialNumber.trim() + ')'
  } else {
    collect_detail.value.remark = collect_detail.value.remark + ' (' + collect_detail.value.serialNumber.trim() + ')'
  }
}


async function checkdataMatch2(list) {
  let list2 = [];
  let t1 = "";
  let t2 = "";
  let t3 = "";
  let t4 = "";
  let t5 = "";
  let t6 = "";


  for (let i = 0; i < list.length; i++) {
    t1 = "";
    t2 = "";
    t3 = "";
    t4 = "";
    t5 = "";
    t6 = "";

    let text = list[i].fullName;
    text = text.split(">>");
    let count = text.length - 1;
    let newText = ''

    if (count > 0) {
      if (count === 1) {
        t1 = text[0];
        t2 = text[1];
        newText = text[1]
      } else if (count === 2) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        newText = text[1] + '>>' + text[2]

      } else if (count === 3) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];


        newText = text[1] + '>>' + text[2] + '>>' + text[3]

      } else if (count === 4) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];
        t5 = text[4];

        newText = text[1] + '>>' + text[2] + '>>' + text[3] + '>>' + text[4]

      } else if (count === 5) {

        t1 = text[0]
        t2 = text[1]
        t3 = text[2]
        t4 = text[3]
        t5 = text[4]
        t6 = text[5]

        newText = text[1] + '>>' + text[2] + '>>' + text[3] + '>>' + text[4] + '>>' + text[5]

      }
    }


    const obj = {
      uid: list[i].uid,
      code: list[i].code,
      detailt: newText.replaceAll(">>", " "),
      fullName: newText,
      datailt: newText,
      t1: t1,
      t2: t2,
      t3: t3,
      t4: t4,
      t5: t5,
      t6: t6,
    };
    list2.push(obj);
  }
  textt.value = "";
  if (list2.length > 0) {
    types.value = list2;
  }
}


async function selection(value) {
  selected.value = value;
  filterText.value = value.datailt;
  typesNew.value = [];
  textt.value = "";
  dataList.value = [];
  // console.log('input : ' + JSON.stringify(selected.value))
  let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf(selected.value.t1.toLowerCase()) >= 0);
  if (ld.length > 0) {
    collect_detail.value.mainCatUid = ld[0].uid
    await getFlaw(ld[0].uid)
  } else {
    collect_detail.value.mainCatUid = '0'
  }
  setRemark()
}


async function newformatinput() {
  filterInput.value = "";
  page_s.value = 0;
  page_e.value = 20;
  typesNew.value = [];
  dataList.value = [];
  collect_detail.value.mainCatUid = '0'
  listflaw.value = []
  openFlaw.value = false
  let list = []
  if (filterText.value.trim().length > 0) {
    if (filterText.value.includes(">>")) {
      let text = filterText.value.replaceAll(/\s/g, "");
      text = text.split(">>");
      let count = text.length - 1;
      if (count > 0) {
        if (count === 1) {
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[1].toLowerCase())
          );
          checkdataMatch(list);
        } else if (count === 2) {
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .includes(text[2].toLowerCase())
          );
          checkdataMatch(list);
        } else if (count === 3) {
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t5
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[3].toLowerCase())
          );
          checkdataMatch(list);
        } else if (count === 4) {
          list = types.value.filter(
            (c) =>
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t5.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t6.toLowerCase().includes(text[4].toLowerCase())
          );
          checkdataMatch(list);
        }
      } else {
        filterInput.value = filterText.value.replaceAll(">>", "");
        list = types.value.filter(
          (c) =>
            c.t2.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t3.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t4.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t5.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t6.toLowerCase().includes(filterInput.value.toLowerCase())
        );
        checkdataMatch(list);
      }

    } else {
      if (
        filterText.value !== "" &&
        filterText.value !== undefined &&
        filterText.value !== null
      ) {

        filterInput.value = filterText.value.replaceAll(">>", "");

        var text = filterInput.value.trim().toLowerCase();
        var arr = text.match(/\S+/g);

        if (arr !== null) {

          list = types.value.filter((c) =>
            allAvailable(c.detailt.toLowerCase(), arr)
          );

          checkdataMatch(list);
        }
      }
    } if (list.length === 0) {
      let ld = listprint.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
      if (ld.length > 0) {
        collect_detail.value.mainCatUid = ld[0].uid
        selected.value = ''
        await getFlaw(ld[0].uid)
        setRemark()
      }
    }

  } else {
    typesNew.value = [];
    textt.value = "";
    setRemark()
  }

}


function allAvailable(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.every((item) => str.includes(item));
}


async function checkdataMatch(list) {
  let list2 = [];
  for (let i = 0; i < list.length; i++) {
    const obj = {
      datailt: list[i].datailt,
      t1: list[i].t1,
      t2: list[i].t2,
      t3: list[i].t3,
      t4: list[i].t4,
      t5: list[i].t5,
      t6: list[i].t6,
      detailt: list[i].detailt,
      fullName: list[i].fullName,
      uid: list[i].uid,
      code: list[i].code
    };
    list2.push(obj);
  }

  textt.value = "";
  if (list2.length > 0) {
    // console.log('list2 : '+list2.length)
    typesNew.value = list2;
    textt.value = "result : " + formatNumberString(list2.length) + " / " + formatNumberString(types.value.length);
    // console.log("textt : " + textt.value);
    fetchData()
    // loadData();
  }
}

async function getFlaw(uid) {
  listflaw.value = []
  let data = "";
  data = {
    categoryUid: uid,
  };
  const payload = data;
  const response = await storetf.flawListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log('getFlaw : ' + JSON.stringify(response.data));
        let list = response.data.data.list;
        list.map(function (x) {
          x.chk = false
          return x
        });
        listflaw.value = list

      }
    }
  }
}

async function openEdit(item) {
  clearItem();
  uid_edit.value = ''
  workcaseDetail.value = null
  listQ02D.value = []
  listQ02E.value = []
  filterText.value = ''
  if (Listdata.value.length === 0) {
    await loadDataApi();
  }

  if (listprint.value.length === 0) {
    await loadDataPrintApi()
  }

  if (listunit.value.length === 0) {
    loadDataUnitApi()
  }
  await getworkCaseInfo(item.uid)
  if (workcaseDetail.value != null) {
    // console.log('item : ' + JSON.stringify(item));
    listQ02E.value = item
    uid_edit.value = workcaseDetail.value.ticket.uid
    oldremark.value = workcaseDetail.value.remark
    listinfo.value = workcaseDetail.value.ticket.collateralInfo
    listQ02D.value = workcaseDetail.value.ticket.collaterals

    listQ02D.value.map(function (x) {
      x.chk = false
      x.remarkL = []
      return x
    });

    if (listQ02D.value.length > 0) {
      for (let i = 0; i < listQ02D.value.length; i++) {
        listQ02D.value[i].remarkL.push({ val: listQ02D.value[i].remark })
      }
    }
    // console.log('listQ02D : ' + JSON.stringify(listQ02D.value));

    document.getElementById("newPawnModal").click();
  }
}


async function setItemDetail(item) {
  clearItem();
  collect_detail.value = {
    uid: item.uid,
    ticketUid: item.ticketUid,
    mainCatUid: item.mainCatUid,
    mainCatCode: item.mainCatCode,
    mainCatName: item.mainCatName,
    categoryUid: item.categoryUid,
    categoryCode: item.categoryCode,
    categoryName: item.categoryName,
    weight: item.weight,
    pawnValue: item.pawnValue,
    fullValue: item.fullValue,
    diamondQuantity: item.diamondQuantity,
    quantity: item.quantity,
    unitUid: item.unitUid,
    unitName: item.unitName,
    remark: item.remark,
    collateralGroupUid: item.collateralGroupUid,
    serialNumber: item.serialNumber,
    version: item.version,
    newPawnValue: item.newPawnValue,
    tmpPawnValue: item.tmpPawnValue,
    tmpNewPawnValue: item.tmpNewPawnValue,
  };
  // console.log('setItemDetail : ' + JSON.stringify(collect_detail.value));
  filterText.value = item.categoryName
  if (collect_detail.value.mainCatUid !== '0') {
    await getFlaw(collect_detail.value.mainCatUid)
  }

  dataList.value = []
  selected.value = ''
  typesNew.value = []
  textt.value = ''


}
async function clearItem() {
  filterText.value = ''
  collect_detail.value = {
    uid: '',
    ticketUid: '',
    mainCatUid: '0',
    mainCatCode: '',
    mainCatName: '',
    categoryUid: '0',
    categoryCode: '',
    categoryName: '',
    weight: '',
    pawnValue: '',
    newPawnValue: '',
    tmpPawnValue: '',
    tmpNewPawnValue: '',
    fullValue: '',
    diamondQuantity: '',
    quantity: '',
    unitUid: '0',
    unitName: '',
    remark: '',
    collateralGroupUid: '',
    version: '',
    serialNumber: ''
  };
}


async function update() {

  if (collect_detail.value.unitUid !== '' && collect_detail.value.unitUid !== '0') {
    collect_detail.value.unitName = filterUnit(collect_detail.value.unitUid)
  }

  if (collect_detail.value.mainCatUid !== '' && collect_detail.value.mainCatUid !== '0') {
    let ll = filterMainCat(collect_detail.value.mainCatUid)
    if (ll !== null) {
      collect_detail.value.mainCatCode = ll.code
      collect_detail.value.mainCatName = ll.name
      // console.log('ll : ' + JSON.stringify(ll.code) + ' ' + JSON.stringify(ll.name));
    }

  }

  if (collect_detail.value.fullValue === '') {
    collect_detail.value.fullValue = 0
  }

  if (collect_detail.value.diamondQuantity === '') {
    collect_detail.value.diamondQuantity = 0
  }

  if (collect_detail.value.weight === '') {
    collect_detail.value.weight = 0
  }

  if (selected.value === '' && collect_detail.value.mainCatName !== 'อื่นๆ') {
    let sl = types.value.filter((c) => c.fullName.toLowerCase().indexOf(filterText.value.trim().toLowerCase()) >= 0);
    if (sl.length > 0) {
      selected.value = sl[0]
    }
  }

  if (selected.value !== '') {
    let ld = types.value.filter((c) => c.uid.toLowerCase().indexOf(selected.value.uid.toLowerCase()) >= 0);
    if (ld.length > 0) {
      if (ld[0].name !== 'อื่นๆ') {
        collect_detail.value.categoryUid = ld[0].uid
        collect_detail.value.categoryCode = ld[0].code
        collect_detail.value.categoryName = ld[0].fullName
      } else {
        collect_detail.value.categoryUid = ''
        collect_detail.value.categoryCode = ''
        collect_detail.value.categoryName = filterText.value.trim()
      }
    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = ''
    }
  } else {

    if (collect_detail.value.mainCatName === 'อื่นๆ') {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
      await loadAssetList(collect_detail.value.mainCatUid)
      if (tplist.value.length > 0) {
        // console.log('tplist : ' + JSON.stringify(tplist.value));
        let ld = tplist.value.filter((c) => c.name.toLowerCase().indexOf('อื่นๆ') >= 0);
        if (ld.length > 0) {
          collect_detail.value.categoryUid = ld[0].uid
          collect_detail.value.categoryCode = ld[0].code
          collect_detail.value.categoryName = filterText.value.trim()
        }
      }
    } else {
      collect_detail.value.categoryUid = ''
      collect_detail.value.categoryCode = ''
      collect_detail.value.categoryName = filterText.value.trim()
    }
  }

  collect_detail.value.weight = parseFloat(collect_detail.value.weight)
  collect_detail.value.newPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.pawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.tmpNewPawnValue = parseFloat(collect_detail.value.newPawnValue)
  collect_detail.value.fullValue = parseFloat(collect_detail.value.fullValue)
  collect_detail.value.diamondQuantity = parseInt(collect_detail.value.diamondQuantity)
  collect_detail.value.quantity = parseInt(collect_detail.value.quantity)




  if (collect_detail.value.unitUid === '0') {
    collect_detail.value.unitUid = ''
    collect_detail.value.unitName = ''
  }

  // console.log('update : ' + JSON.stringify(collect_detail.value));

  wanninglist.value = []
  if (collect_detail.value.serialNumber !== '' && collect_detail.value.serialNumber !== null) {
    await findsanctionAsset(collect_detail.value.serialNumber)
    if (wanninglist.value.length > 0) {
      const myModal = document.getElementById('searchSanctionModal');
      const modal = new Modal(myModal); // Instantiates your modal
      modal.show();
    } else {
      updateItems()
      clearItem()
    }
  } else {
    updateItems()
    clearItem()
  }



}


function filterMainCat(str) {
  let name = listprint.value.filter((c) => c.uid === str);
  return name[0];
}

function filterUnit(str) {
  let name = listunit.value.filter((c) => c.uid === str);
  return name[0].name;
}

async function updateItems() {
  const payload = collect_detail.value;
  payload.remark = payload.remark.trim()
  payload.version = workcaseDetail.value.ticket.version
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storecl.updateAfterCompleteFetch(payload);
  // console.log('updateItems : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value.ticket.version = response.data.data.version
        swal({
          icon: 'success',
          title: 'บันทึกสำเร็จ',
          text: '',
          target: '#newPawnListModal',
          customClass: {
            container: 'loading-state'
          },
        })
        getcollateralList();
      } else {
        swal({
          icon: 'error',
          title: 'บันทึกไม่สำเร็จ',
          text: response.data.rtnDesc,
          target: '#newPawnListModal',
          customClass: {
            container: 'loading-state'
          },
        })

      }
    }
  }
}

async function getcollateralList() {
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    tuid: uid_edit.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storecl.collateralListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.map(function (x) {
          x.chk = false
          x.remarkL = []
          return x
        });


        if (list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            list[i].remarkL.push({ val: list[i].remark })
          }
        }

        listQ02D.value = list

      }
    }
  }
}


async function findsanctionAsset(serial) {
  // 'abc-123' , 'ccc888'
  let data = "";
  data = {
    serialNumber: serial.trim(),
  };
  const payload = data;
  // console.log("ass : " + JSON.stringify(payload));
  const response = await storeass.sanctionaAssetFetch(payload);
  // console.log("ass : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          wanninglist.value.push(response.data.data)
          // console.log("ass : " + JSON.stringify(response.data));
        }

      }
    }
  }
}

// Fetch data function
const fetchData = async () => {
  try {
    loading_l.value = true;
    await new Promise(res => setTimeout(res, 500));
    // console.log('typesNew fetch : ' + JSON.stringify(typesNew.value))
    const res = typesNew.value.slice(page_s.value, page_e.value);
    // console.log('res fetch : ' + JSON.stringify(res))
    dataList.value.push(...res);
    page_s.value = page_s.value + res.length;
    page_e.value = page_s.value + limit;
    showSuggestions.value = true;

    let re = String(res.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    let ret = String(dataList.value.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } catch (error) {
    console.error('Error fetching data:', error);
    loading_l.value = false;
  } finally {
    loading_l.value = false;
  }
};

// Handle scroll event for infinite loading
const handleScroll = () => {
  const scrollContainer = list.value;
  // console.error('scrollTop :', scrollContainer.scrollTop);
  // console.error('clientHeight :', scrollContainer.clientHeight);
  // console.error('scrollHeight :', scrollContainer.scrollHeight);
  if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
    // Reached the bottom of the scroll container, load more data
    if (dataList.value.length <= 10) {
      // console.log("data : " + dataList.value.length);
      scrollContainer.scrollTo(0, 0);
    }

    if (typesNew.value.length > 0) {
      fetchData();
    }

  }
};

// Function to update widths
const updateWidths = () => {
  if (parentRef.value && list.value) {
    parentWidth.value = parentRef.value.clientWidth;
    childWidth.value = parentRef.value.clientWidth;
  }
}
/* eslint-disable no-useless-escape */
const updateText = () => {
  let inp = document.querySelector("#inp");
  let list = document.querySelectorAll('#myList li')
  // console.log("updated() list : " + list.length);
  var filtter = inp.value.trim().replaceAll(">>", " ");
  var text = filtter.toLowerCase().replaceAll("'", " ");
  var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  var repl = text.replace(invalid, "")
  var arrt = repl.match(/\S+/g);

  if (arrt !== null) {
    const arr = Array.from(list);
    arr.forEach((x) => {
      // if (re.test(x.textContent)) {
      const txtValue = x.innerText || x.textContent;
      var string = txtValue;

      var finalAns = string;
      for (var i = arrt.length - 1; i >= 0; i--) {
        finalAns = finalAns.replace(
          RegExp(arrt[i], "gi"),
          '<b style="color:orange;">$&</b>'
        );
      }
      x.innerHTML = finalAns;
      x.style.display = "block";
    });
  }
}


function cancelBtn() {
  time.value = false;
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  type_search.value = '';
  serial_search.value = '';
  currentPage.value = 1
  listhistory.value = []
  listticketchain.value = []
  totalRows_s.value = ''
  search_status.value = ''
  tx_search.value = ''
  totalQuantity.value = 0;
  totalPawnValue.value = 0;
  totalWeight.value = 0
  totalInterest.value = 0
}

function resetinput() {
  time.value = false;
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  end_date.value = new Date();
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  type_search.value = '';
  search_status.value = '';
  tx_search.value = ''
}

function changeTx() {
  type_search.value = '';
}


function monthDiff() {
  // let diff = ''
  let pro = false
  // diff = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12 + (end_date.value.getMonth() - start_date.value.getMonth())
  // // diff = end_date.value.getMonth() - start_date.value.getMonth() +
  // //   (12 * (end_date.value.getFullYear() - start_date.value.getFullYear()))
  var months;
  months = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12;
  months += end_date.value.getMonth() - start_date.value.getMonth();

  if (time.value && months > 3) {
    pro = true
  }
  return pro
}

const loadDataHistoryApi = async () => {
  // console.log("start_date  : " + moment(new Date(start_date.value)).format('YYYY-MM-DDThh:mm:00') + " end_date : " + moment(new Date(end_date.value)).local());
  let data = "";
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00')
    ed = moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00')
  }
  if (cusUid_search.value !== '' || tk_search.value || type_search.value !== '' || serial_search.value !== ''
    || search_status.value !== '' || tx_search.value !== '') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
    type: type_search.value,
    serialNumber: serial_search.value,
    shopUid: shop_detail.value.uid,
    status: search_status.value,
    txFilter: tx_search.value


  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storewc.workCaseHistoryFetch(payload);
  // console.log("loadHistory : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        totalQuantity.value = response.data.data.ext.totalQuantity;
        totalPawnValue.value = response.data.data.ext.totalNewPawnValue;
        totalWeight.value = response.data.data.ext.totalWeight;
        totalInterest.value = response.data.data.ext.totalInterest;
      }
    }
  }

}




const loadDataPrintApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  let data = "";
  data = {
    uid: "",
  };
  const payload = data;
  // const response = await store.dispatch("categorylistPrintFetch", payload);
  const response = await storec.categorylistPrintFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listprint.value = response.data.data;
        // console.log('Listdata : ' + JSON.stringify(listprint.value))
      }
    }
  }
};


async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}


async function getListChain() {
  let data = "";
  data = {
    index: currentPageT.value - 1,
    size: perPageT.value,
    uid: chain_uid.value,
    shopUid: shop_detail.value.uid,
  };
  const payload = data;

  const response = await storewc.workCaseListChainFetch(payload);
  // console.log("getListChain : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listticketchain.value = response.data.data.list;
        totalRowsT.value = response.data.data.ext.total
        totalRows_sT.value = formatNumberString(totalRowsT.value)
      }
      onClickTab('2')
    }
  }
}

async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  // console.log("calIntInt p : " + JSON.stringify(payload));
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt r : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("calIntInt : " + JSON.stringify(res));
        // int_cal.value = Number(res.promoInterest) + Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("int_cal : " + int_cal.value);
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: shop_detail.value.uid,
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}


async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  const response = await storep.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}

function filterCat(str) {
  let side = ''
  let name = listprint.value.filter((c) => c.uid === str);
  if (name.length > 0) {
    side = name[0].name
  }
  return side
}


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function checkProcess(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'เสร็จสิ้น'
  } else if (str === 'ACTIVE') {
    side = 'กำลังดำเนินการ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'TERMINATE') {
    side = 'ยกเลิก'
  }
  return side
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' },
  { name: 'QR_CODE' },
  ]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].feeReceive
      // fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}




async function onClickTab(val) {
  if (val === '1') {
    if ((time.value && monthDiff()) || cusName_search.value !== '' && tk_search.value !== '' && serial_search.value !== '') {
      loadDataHistoryApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
  }
}


async function setfirstTab() {
  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}


async function setsecondTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.add('show', 'active')
  }
}

async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  cusUid_search.value = item.uid
  cusName_search.value = item.nameTh
  document.getElementById("closeModal").click();
}

async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePagination() {
  listhistory.value = []
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DDTHH:mm:00')
    ed = moment(new Date(end_date.value)).format('YYYY-MM-DDTHH:mm:00')
  }
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
    type: type_search.value,
    serialNumber: serial_search.value,
    shopUid: shop_detail.value.uid,
    status: search_status.value,
    txFilter: tx_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storewc.workCaseHistoryFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        totalQuantity.value = response.data.data.ext.totalQuantity;
        totalPawnValue.value = response.data.data.ext.totalNewPawnValue;
        totalWeight.value = response.data.data.ext.totalWeight;
        totalInterest.value = response.data.data.ext.totalInterest;
      }
    }
  }

}


async function updatePaginationT() {
  listticketchain.value = []
  let data = "";
  data = {
    index: currentPageT.value - 1,
    size: perPageT.value,
    uid: chain_uid.value,
  };
  const payload = data;

  const response = await storewc.workCaseListChainFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listticketchain.value = response.data.data.list;
        totalRowsT.value = response.data.data.ext.total
        totalRows_sT.value = formatNumberString(totalRowsT.value)
      }
    }
  }

}



async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}


function setGallery() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {
    if (i == 0) {
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;
}


async function openDetail(item) {
  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  paymentE.value = ''
  await getworkCaseInfo(item.uid)
  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await detailPayment(workcaseDetail.value.paymentGroupUid)

    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()

    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      } else if (workcaseDetail.value.status === 'ACTIVE') {
        tk_status.value = 'กำลังดำเนินการ'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}

async function openListChain(item) {
  chain_uid.value = item.uid
  currentPageT.value = 1
  listticketchain.value = []
  await getListChain()
}


async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function getTicketImage() {
  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }
    if (url.length > 0) {
      await getFile(url)
      // setTimeout(3000);
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}



async function ticketGen(item) {
  await getticket(item.uid)
}

async function backCoverGen(item) {
  await getbackcover(item.uid)
}

async function getticket(id) {
  // console.log('id : ' + id)
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/ticket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function getbackcover(id) {
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/backCover/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}


async function addfileApi(fileInput) {
  thumb_id.value = ""
  let date = new Date()
  let data = "";
  data = {
    name: 'ticketimg-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds(),
    data: fileInput,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload.name));
  // const response = await store.dispatch("addFetch", payload);
  const response = await stored.addFetch(payload);
  // console.log('addfileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
        // console.log('thumb_id : ' + thumb_id.value);
        if (thumb_id.value !== '') {
          await addTicketImage()
        }

      } else {
        err_msg.value = response.data.rtnDesc
        // swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addTicketImage() {
  let data = "";
  data = {
    ticketUid: uid_edit.value, // always required
    uid: "0",
    imageUid: thumb_id.value,
    remark: "",
    version: "0"
  };

  const payload = data;
  // console.log('addTicketImage : ' + JSON.stringify(payload));
  // const response = await store.dispatch("addFetch", payload);
  const response = await storeti.ticketImageNewFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log('addTicketImage : ' + JSON.stringify(response.data));
        count_success.value = Number(count_success.value) + 1
        // console.log('count_success : ' + count_success.value);
      } else {
        err_msg.value = response.data.rtnDesc
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


const loadAssetList = async (uid) => {
  tplist.value = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    uid: uid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.categoryListFetch(payload);
  // console.log("response : " + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        tplist.value = response.data.data.list
      }
    }
  };
}

function buildTableBody(data, columns) {
  var body = [];

  body.push(columns);

  data.forEach(function (row) {
    var dataRow = [];

    columns.forEach(function (column) {
      dataRow.push(row[column].toString());
    })

    body.push(dataRow);
  });

  return body;
}

function table(data, columns) {
  return {
    table: {
      headerRows: 1,
      body: buildTableBody(data, columns)
    }
  };
}

function Pdftest() {
  // var externalDataRetrievedFromServer = [
  //   { Name: 'Bartek', refNumber: 34, newPawnValue: 1.78 },
  //   { Name: 'John', refNumber: 27, newPawnValue: 1.79 },
  //   { Name: 'Elizabeth', refNumber: 30, newPawnValue: 1.80 },
  // ];
  var externalDataRetrievedFromServer = listhistory.value;

  var dd = {
    content: [
      { text: 'Dynamic parts', style: 'header' },
      table(externalDataRetrievedFromServer, ['customerName', 'refNumber', 'newPawnValue'])
    ],

    defaultStyle: {
      font: 'THSarabunNew'
    }
  }
  pdfMake.createPdf(dd).open()
}

onUpdated(() => {
  updateWidths();
  updateText()

})

onMounted(() => {
  updateWidths();
  window.addEventListener('resize', updateWidths);

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
});


</script>

<style lang="scss" scoped>
.inline-icon {
  display: inline-flex;
  vertical-align: top;
  font-size: 20px !important;
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  max-width: 990px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.btnClose {
  border-color: transparent !important;
  background-color: white !important;
  vertical-align: baseline;

  i.material-icons {
    padding-top: 8px;
    color: gray-600;
  }
}

.grid-containerP {
  max-width: 990px;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.wrapper {
  position: relative;
}

.myLayer {
  position: absolute;
  /* becomes a layer */
  // left: 0;
  /* relative to its parent wrapper */
  top: -18px;
  right: -23px;
  /* relative to its parent wrapper */
  z-index: 2;
  font-size: 20px;
}
</style>
