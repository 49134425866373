<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <ul id="transactionsTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="thrid-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#thridTransactions"
              type="button" role="tab" aria-controls="thridTransactions" aria-selected="true" @click="onClickTab('3')">
              {{ $t("content.reportreconcile") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="first-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstTransactions"
              type="button" role="tab" aria-controls="firstTransactions" aria-selected="false" @click="onClickTab('1')">
              {{ $t("content.recmoneyhis") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondTransactions"
              type="button" role="tab" aria-controls="secondTransactions" aria-selected="false"
              @click="onClickTab('2')">
              {{ $t("content.recmoneybiz") }}
            </button>
          </li>



        </ul>

        <div id="transactionsTabContent" class="tab-content">
          <div id="firstTransactions" class="tab-pane fade" role="tabpanel" aria-labelledby="first-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.recmoneyhis") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                    <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      :text-input="textInputOptions" fluid />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      fluid :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{
                      $t("content.amnsourcemoney")
                    }}</label>
                    <select v-model="search_sourcemoney" class="form-select">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <optgroup :label="$t('apps.mcash')">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.status") }}</label>
                    <select class="form-select" v-model="search_status">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option value="APPROVE" selected>APPROVE</option>
                      <option value="DRAFT" selected>DRAFT</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadDataApi">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ $t("buttons.sear") }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.fromsource") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.recbalance") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listrec" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_datetime(row.endDate) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterMoneySource(row.accountUid) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.closeAmount) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.diffAmount > 0" style="color:green;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else-if="row.diffAmount < 0" style="color:red;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else style="color:blue;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>

                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.remark }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.fundStatus }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button :disabled="row.fundStatus !== 'DRAFT'" color="danger" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItems(row)">
                              <i class=" material-icons material-symbols-outlined">delete</i>
                            </material-button>
                            <material-button v-if="row.fundStatus === 'DRAFT'" color="success" variant="outline"
                              class="rounded-circle me-1" data-bs-toggle="tooltip" data-bs-placement="top"
                              :title="btnApprove" @click="onApprove(row)">
                              <i class="material-icons material-symbols-outlined">done_all</i>
                            </material-button>
                          </td>

                        </tr>
                        <tr v-if="listrec.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listrec.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="secondTransactions" class="tab-pane fade" role="tabpanel" aria-labelledby="second-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.recmoneybiz") }}</h5>
              </div>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t("content.fromsource") }}</label>
                    <select v-model="reconcile_detail.accountUid" class="form-select"
                      :disabled="reconcile_detail.uid !== ''" @change="confirmNewRecord">
                      <option value="0" disabled selected>{{ $t("content.select") }}</option>
                      <optgroup :label="$t('apps.mcash')">
                        <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('content.amnbankacc')">
                        <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                          {{ item.shortName }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                      @click="cancelBtn">
                      <i class="material-icons material-symbols-outlined">close</i>
                      {{ $t("buttons.canc") }}
                    </material-button>
                  </div>
                </div>
                <div class="row">
                  <div class="row">
                    <pre></pre>
                    <pre></pre>
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-flush">
                            <thead class="thead-light">
                              <tr>
                                <th class="align-top text-center">{{ $t("content.transc") }}</th>
                                <th class="align-top text-center">{{ $t("content.amnincome") }}</th>
                                <th class="align-top text-center">{{ $t("content.amnoutcome") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, index) in listtx" :key="index">
                                <td class="text-sm font-weight-normal">
                                  {{ $t(listtx[index].items) }}
                                </td>
                                <td class="text-sm font-weight-normal text-center" style="color:green;">
                                  {{ filterIncome(listtx[index].val) }}
                                </td>
                                <td class="text-sm font-weight-normal text-center" style="color:red;">
                                  {{ filterOutcome(listtx[index].val) }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>

                                <td class="font-weight-bold text-center">
                                  {{ t("content.gtotal") }}
                                </td>

                                <td class="font-weight-normal text-center" style="color:green;">
                                  <span v-if="reconcile_detail.txCashInflow !== 0">
                                    {{ formatNumberDecimalString(reconcile_detail.txCashInflow) }}
                                  </span>

                                </td>
                                <td class="font-weight-normal text-center" style="color:red;">
                                  <span v-if="reconcile_detail.txCashOutflow !== 0">
                                    {{ formatNumberDecimalString(reconcile_detail.txCashOutflow) }}
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                    <pre></pre>
                    <pre></pre>

                    <div class="col-lg-12">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-flush table-borderless">
                            <tbody>
                              <tr>
                                <td class="font-weight-bold text-center">
                                  {{ $t("content.fromsource") }} :
                                  <span v-if="reconcile_detail.accountUid !== '0'" style="color:blue;">
                                    {{ filterMoneySource(reconcile_detail.accountUid) }}
                                  </span>
                                  <span v-else>
                                    {{ $t("content.fromsource") }} : -
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold text-center">
                                  {{ $t("content.amountop") }} :
                                  {{ formatNumberDecimalString(reconcile_detail.openAmount) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold text-center">
                                  {{ $t("content.amountcl") }} : {{
                                    formatNumberDecimalString(reconcile_detail.closeAmount) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold text-center">
                                  {{ $t("content.recbalance") }} :
                                  <span v-if="reconcile_detail.diffAmount > 0" style="color:green;">
                                    {{ formatNumberDecimalString(reconcile_detail.diffAmount) }}&nbsp;
                                    <span v-if="reconcile_detail.accountType === 'CASH_ACCOUNT'" style="color:black;">
                                      ({{ $t("content.cash_sh") }})
                                    </span>
                                    <span v-else style="color:black;">
                                      ({{ $t("content.bank_sh") }})
                                    </span>
                                  </span>
                                  <span v-else-if="reconcile_detail.diffAmount < 0" style="color:red;">
                                    {{ formatNumberDecimalString(reconcile_detail.diffAmount) }}&nbsp;
                                    <span v-if="reconcile_detail.accountType === 'CASH_ACCOUNT'" style="color:black;">
                                      ({{ $t("content.cash_ov") }})
                                    </span>
                                    <span v-else style="color:black;">
                                      ({{ $t("content.bank_ov") }})
                                    </span>
                                  </span>
                                  <span v-else style="color:blue;">
                                    {{ formatNumberDecimalString(reconcile_detail.diffAmount) }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <pre></pre>

                    <div class="col-lg-12 d-flex">
                      <span class="form-label m-0">{{ $t("content.note") }}</span>
                      <div class="col-10 d-inline-block mx-4">
                        <material-input v-model="reconcile_detail.remark" type="text" autocomplete="off"
                          :disabled="reconcile_detail.fundStatus !== 'DRAFT'" />
                      </div>
                    </div>
                  </div>

                  <div class="row no-gutters mt-3">
                    <div class="col-12 d-flex justify-content-end">
                      <div>
                        <material-button color="primary" class="float-lg-start float-sm-end mb-3" :disabled="reconcile_detail.remark === null || (reconcile_detail.remark.trim() === '' && reconcile_detail.remark !== null)
                          || reconcile_detail.uid === '' || (reconcile_detail.fundStatus !== 'DRAFT')"
                          @click="updateRemark">
                          <i class="material-icons material-symbols-outlined">save</i>
                          {{ btnSave }}
                        </material-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id="thirdTransactions" class="tab-pane fade" role="tabpanel" aria-labelledby="third-transactions">
            <div class="card mt-4">
              <div class="card-header mb-0">
                <h5>{{ $t("content.reportreconcile") }}
                  <span style="color:blue;">
                    {{ format_date(new Date()) }}
                  </span>
                </h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <h6>{{ $t("apps.mcash") }}</h6>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th>{{ $t("apps.mcash") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.recbalance") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.status") }}</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listrec_acc" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ filterMoneySource(row.accountUid) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ format_datetime(row.endDate) }}
                            </span>
                          </td>

                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ formatNumberDecimalString(row.closeAmount) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.diffAmount > 0 && row.rec_uid !== '0'" style="color:green;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else-if="row.diffAmount < 0 && row.rec_uid !== '0'" style="color:red;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else style="color:blue;">
                              <span v-if="row.rec_uid !== '0'">
                                {{ formatNumberDecimalString(row.diffAmount) }}
                              </span>
                            </span>

                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ row.remark }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ row.fundStatus }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="listrec_acc.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{
                            formatNumberString(listrec_acc.length) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>


            <div class="card mt-4">
              <div class="card-header mb-0">
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <h6>{{ $t("content.amnbankacc") }}</h6>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th>{{ $t("content.amnbankacc") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.recbalance") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.status") }}</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listrec_bk" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ filterMoneySource(row.accountUid) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ format_datetime(row.endDate) }}
                            </span>
                          </td>

                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ formatNumberDecimalString(row.closeAmount) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.diffAmount > 0 && row.rec_uid !== '0'" style="color:green;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else-if="row.diffAmount < 0 && row.rec_uid !== '0'" style="color:red;">
                              {{ formatNumberDecimalString(row.diffAmount) }}
                            </span>
                            <span v-else style="color:blue;">
                              <span v-if="row.rec_uid !== '0'">
                                {{ formatNumberDecimalString(row.diffAmount) }}
                              </span>
                            </span>

                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ row.remark }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.rec_uid !== '0'">
                              {{ row.fundStatus }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="listrec_bk.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{
                            formatNumberString(listrec_bk.length) }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

              </div>
            </div>



          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputNumber from 'primevue/inputnumber';
import { reactive, inject, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ExampleFive from "@/examples/ExampleOne";


import { useCashAccountStore } from '@/stores/cashaccount'
import { useShopBankStore } from '@/stores/shopbank'
import { useFinancialTranTypeStore } from '@/stores/financialtrantype'
import { useFundTxStore } from '@/stores/fundtx'
import { useReconcileStore } from '@/stores/reconcile'


import moment from 'moment'


const storec = useCashAccountStore()
const storesb = useShopBankStore()
const storef = useFinancialTranTypeStore()
const storefund = useFundTxStore()
const storerec = useReconcileStore()

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const { t } = useI18n();
const swal = inject("$swal");

const btnApprove = computed(() => t("buttons.apprv"))
const btnSave = computed(() => t("buttons.save"));
const btnEdit = computed(() => t("buttons.edit"));
const btnDel = computed(() => t("buttons.remo"));
const shop_detail = ref(null)
const listcashAcc = ref([]);
const listbankpawn = ref([]);
const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const listfinancialtrans = ref([]);
const listrec = ref([]);
const listtx = ref([
  { items: computed(() => t("content.cuttrans")), val: 'PAWN_TRANSACTION' },
  { items: computed(() => t("content.txfee")), val: 'FEE_TRANSACTION' },
  { items: computed(() => t("content.ctranstin")), val: 'INTERNAL' },
  { items: computed(() => t("content.ctranstout")), val: 'EXTERNAL' },
  { items: computed(() => t("content.direct_lo")), val: 'DIRECTOR_LOAN' },
  { items: computed(() => t("content.sell_pr")), val: 'SELLING_PRODUCTS' },
  { items: computed(() => t("content.fee")), val: 'FEE' },
  { items: computed(() => t("content.ctranstexp")), val: 'EXPENSE' },
  { items: computed(() => t("content.ctranstacc")), val: 'ACCOUNT_CLOSURE' },
]);

const search_sourcemoney = ref('');
const search_status = ref('');

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const reconcile_detail = ref({
  uid: '',
  groupUid: '',
  shopUid: '',
  startDate: new Date(),
  endDate: new Date(),
  reconcileDate: new Date(),
  accountType: '',
  accountUid: '0',
  openAmountUid: 0,
  openAmount: 0,
  closeAmountUid: 0,
  closeAmount: 0,
  txCashInflow: 0,
  txCashOutflow: 0,
  diffAmount: 0,
  remark: '',
  fundStatus: '',
  approveBy: '0',
  approveDate: null,
  version: '',
  details: []
});
const listrec_acc = ref([]);
const listrec_bk = ref([]);
const process = ref('');



const onClickHandler = () => {
  updatePagination()
};


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  } else {
    return '-'
  }
}

const getshopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        await loadCashAccDataApi()
        await loadBankPawnApi()
        await loadFinancialtransApi()
        await loadReconcile()
        // loadfundCategoryApi()
        // getTxTypeApi()

      }
    }
  }
}

getshopInfoApi()

const loadBankPawnApi = async (val) => {
  listbankpawn.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listbankpawn.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listbankpawn.value.push(listd[i])
          }
        }
      }
    }
  }
}


const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listcashAcc.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('BO_WEB')) {
            listcashAcc.value.push(listd[i])
          }
        }
      }
    }
  }
}

const loadFinancialtransApi = async (val) => {
  listfinancialtrans.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storef.financialTranTypeListFetch(payload);
  // console.log("loadFinancialtransApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfinancialtrans.value = response.data.data.list;
      }
    }
  }
}

const getTxTypeApi = async () => {

  const response = await storefund.getTxTypeFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("TxType : " + JSON.stringify(response.data.data));
      }
    }
  }
}

const loadfundCategoryApi = async () => {

  const response = await storefund.getfundCategoryInfoFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("fundCategory : " + JSON.stringify(response.data.data));
      }
    }
  }
}


async function cancelBtn() {
  if (process.value === 'edit') {
    await backfirstTab()
  } else {
    clearItem()
  }
}

const loadDataApi = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  if (currentPage.value !== 1) {
    if (listrec.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  if (search_sourcemoney.value !== '' || search_status.value !== '' && val !== 'del') {
    currentPage.value = 1
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    accountUid: search_sourcemoney.value,
    fundStatus: search_status.value

  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storerec.reconcileListFetch(payload);
  // console.log("loadDataApi  res : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listrec.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


async function updatePagination() {
  listrec.value = []
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')


  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    accountUid: search_sourcemoney.value,
    fundStatus: search_status.value
  };


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storerec.reconcileListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listrec.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


const loadReconcile = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''
  let list = []
  sd = moment(new Date()).format('YYYY-MM-DD')
  ed = moment(new Date()).format('YYYY-MM-DD')

  data = {
    index: 0,
    size: 100,
    start: sd,
    end: ed,
    accountUid: '',
    fundStatus: 'APPROVE'
  };

  listrec_acc.value = listcashAcc.value;
  listrec_bk.value = listbankpawn.value;

  listrec_acc.value.map(function (x) {
    x.accountUid = x.uid
    x.endDate = null
    x.closeAmount = 0
    x.diffAmount = 0
    x.remark = ''
    x.fundStatus = ''
    x.rec_uid = '0'
    return x
  });


  listrec_bk.value.map(function (x) {
    x.accountUid = x.uid
    x.endDate = null
    x.closeAmount = 0
    x.diffAmount = 0
    x.remark = ''
    x.fundStatus = ''
    x.rec_uid = '0'
    return x
  });


  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storerec.reconcileListFetch(payload);
  // console.log("loadDataApi  res : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        for (let i = 0; i < listrec_acc.value.length; i++) {
          let lp = list.filter((c) => c.accountType === 'CASH_ACCOUNT' && c.accountUid === listrec_acc.value[i].uid);
          if (lp.length > 0) {
            listrec_acc.value[i].accountUid = lp[lp.length - 1].accountUid
            listrec_acc.value[i].endDate = lp[lp.length - 1].endDate
            listrec_acc.value[i].closeAmount = lp[lp.length - 1].closeAmount
            listrec_acc.value[i].diffAmount = lp[lp.length - 1].diffAmount
            listrec_acc.value[i].remark = lp[lp.length - 1].remark
            listrec_acc.value[i].fundStatus = lp[lp.length - 1].fundStatus
            listrec_acc.value[i].rec_uid = lp[lp.length - 1].uid
          }
        }

        for (let i = 0; i < listrec_bk.value.length; i++) {
          let lp = list.filter((c) => c.accountType === 'BANK_ACCOUNT' && c.accountUid === listrec_bk.value[i].uid);
          if (lp.length > 0) {
            listrec_bk.value[i].accountUid = lp[lp.length - 1].accountUid
            listrec_bk.value[i].endDate = lp[lp.length - 1].endDate
            listrec_bk.value[i].closeAmount = lp[lp.length - 1].closeAmount
            listrec_bk.value[i].diffAmount = lp[lp.length - 1].diffAmount
            listrec_bk.value[i].remark = lp[lp.length - 1].remark
            listrec_bk.value[i].fundStatus = lp[lp.length - 1].fundStatus
            listrec_bk.value[i].rec_uid = lp[lp.length - 1].uid
          }
        }
      }
    }
  }
}



function checkMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = "BANK_ACCOUNT"
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = "CASH_ACCOUNT"
      }
    }
  }
  return side
}


function filterCash(str) {
  return listcashAcc.value.filter((c) => c.uid === str);
}

function filterBank(str) {
  return listbankpawn.value.filter((c) => c.uid === str);
}


function filterIncome(str) {
  let amount = ''
  let ld = reconcile_detail.value.details.filter((c) => c.fundCategory === str && c.cashInflow);
  if (ld.length > 0 && ld[0].cashInflow !== 0) {
    amount = formatNumberDecimalString(ld[0].cashInflow)
  }
  return amount
}

function filterOutcome(str) {
  let amount = ''
  let ld = reconcile_detail.value.details.filter((c) => c.fundCategory === str && c.cashOutflow);
  if (ld.length > 0 && ld[0].cashOutflow !== 0) {
    amount = formatNumberDecimalString(ld[0].cashOutflow)
  }
  return amount
}


function filterMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = ld[0].shortName
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}



async function confirmNewRecord() {
  swal({
    title: "บันทึกธุรกรรม",
    text: "คุณต้องการบันทึกธุรกรรมนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addReconcile()
    } else if (result.dismiss == 'cancel') {
      clearItem()
    }
  });
}

async function onApprove(item) {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการอนุมัติรายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== '') {
        approveReconcile(item.uid)
      }
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      if (item.uid !== '') {
        reconcileInfo(item.uid)
      }

    } else if (result.dismiss == 'cancel') {
      clearItem()
    }
  })
}


async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteReconcile(item.uid)
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


function clearItem() {
  reconcile_detail.value = {
    uid: '',
    groupUid: '',
    shopUid: '',
    startDate: new Date(),
    endDate: new Date(),
    reconcileDate: new Date(),
    accountType: '',
    accountUid: '0',
    openAmountUid: 0,
    openAmount: 0,
    closeAmountUid: 0,
    closeAmount: 0,
    txCashInflow: 0,
    txCashOutflow: 0,
    diffAmount: 0,
    remark: '',
    fundStatus: '',
    approveBy: '0',
    approveDate: null,
    version: '',
    details: []
  };

}


async function addReconcile() {
  const payload = reconcile_detail.value
  if (payload.accountUid !== '0') {
    payload.accountType = checkMoneySource(payload.accountUid)
  }
  payload.startDate = moment(new Date(payload.startDate)).format('YYYY-MM-DDT00:00:00')
  payload.endDate = moment(new Date(payload.endDate)).format('YYYY-MM-DDTHH:mm:ss')
  // console.log('addReconcile p : ' + JSON.stringify(payload));
  const response = await storerec.reconcileAddFetch(payload);
  // console.log('addReconcile res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        reconcile_detail.value = response.data.data
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function approveReconcile(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('approve p : ' + JSON.stringify(payload));
  const response = await storerec.reconcileApproveFetch(payload);
  // console.log('approve r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateRemark() {
  let payload = reconcile_detail.value
  if (payload.remark !== null) {
    payload.remark = payload.remark.trim()
  }
  // console.log('update p : ' + JSON.stringify(payload));
  const response = await storerec.reconcileUpdateRemarkFetch(payload);
  // console.log('update r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deleteReconcile(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('del p : ' + JSON.stringify(payload));
  const response = await storerec.reconcileDeleteFetch(payload);
  // console.log('del r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function reconcileInfo(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('reconcileInfo p : ' + JSON.stringify(payload));
  const response = await storerec.reconcileInfoFetch(payload);
  // console.log('reconcileInfo res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        reconcile_detail.value = response.data.data
        process.value = 'edit'
        await setsecondTab()
      } else {
        swal("ดึงข้อมูลไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}





async function onClickTab(val) {
  if (val === '1') {
    await setfirstTab()
    if (end_date.value >= start_date.value) {
      await loadDataApi()
    }
  } else if (val === '2') {
    await setsecondTab()
  } else if (val === '3') {
    await setthirdTab()
    await loadReconcile()
  }
}

async function backfirstTab() {
  clearItem()
  await onClickTab('1')
  process.value = ''
}

async function setfirstTab() {
  if (document.getElementById("second-transactions")) {
    document.getElementById("second-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("secondTransactions")) {
    document.getElementById("secondTransactions").classList.remove('show', 'active')
  }

  if (document.getElementById("thrid-transactions")) {
    document.getElementById("thrid-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdTransactions")) {
    document.getElementById("thirdTransactions").classList.remove('show', 'active')
  }

  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.add('show', 'active')
  }
}


async function setsecondTab() {
  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.remove('show', 'active')
  }

  if (document.getElementById("thrid-transactions")) {
    document.getElementById("thrid-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdTransactions")) {
    document.getElementById("thirdTransactions").classList.remove('show', 'active')
  }

  if (document.getElementById("second-transactions")) {
    document.getElementById("second-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("secondTransactions")) {
    document.getElementById("secondTransactions").classList.add('show', 'active')
  }
}

async function setthirdTab() {
  if (document.getElementById("first-transactions")) {
    document.getElementById("first-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("firstTransactions")) {
    document.getElementById("firstTransactions").classList.remove('show', 'active')
  }
  if (document.getElementById("second-transactions")) {
    document.getElementById("second-transactions").classList.remove('show', 'active')
  }

  if (document.getElementById("secondTransactions")) {
    document.getElementById("secondTransactions").classList.remove('show', 'active')
  }
  if (document.getElementById("thrid-transactions")) {
    document.getElementById("thrid-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("thirdTransactions")) {
    document.getElementById("thirdTransactions").classList.add('show', 'active')
  }
}


onMounted(() => {

  if (document.getElementById("thrid-transactions")) {
    document.getElementById("thrid-transactions").classList.add('show', 'active')
  }

  if (document.getElementById("thirdTransactions")) {
    document.getElementById("thirdTransactions").classList.add('show', 'active')
  }
});

</script>

<style lang="scss" scoped></style>
