<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="userAccount" :nav-text="data.user" :nav-sub-text="data.role"
          :class="getRoute() === 'userAccount' ? 'active' : ''">
          <template #icon>
            <img src="../../assets/img/team-2.jpg" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(ac, setAccount) in account" :key="setAccount" :to="{ name: ac.path }"
                :mini-icon="ac.icon" :text="ac.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <ul class="nav pe-0">
          <sidenav-item v-for="(ds, setMain) in dashboard" :key="setMain" :to="{ name: ds.path }" :mini-icon="ds.icon"
            :text="ds.name" />
        </ul>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="dashboardsExamples" :nav-text="t('apps.general')"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">business_center</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(gn, setGeneral) in general" :key="setGeneral" :to="{ name: gn.path }"
                :mini-icon="gn.icon" :text="gn.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="financialsExamples" :nav-text="t('apps.financial')"
          :class="getRoute() === 'financial' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">payments</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(fn, setFinancial) in financial" :key="setFinancial" :to="{ name: fn.path }"
                :mini-icon="fn.icon" :text="fn.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="customersExamples" :nav-text="t('apps.customer')"
          :class="getRoute() === 'customer' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">support_agent</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(cm, setCustomer) in customer" :key="setCustomer" :to="{ name: cm.path }"
                :mini-icon="cm.icon" :text="cm.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="stocksExamples" :nav-text="t('apps.stocks')"
          :class="getRoute() === 'stock' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">inventory_2</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(st, setStock) in stock" :key="setStock" :to="{ name: st.path }" :mini-icon="st.icon"
                :text="st.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="reportsExamples" :nav-text="t('apps.report')"
          :class="getRoute() === 'report' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">summarize</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(rp, setReport) in report" :key="setReport" :to="{ name: rp.path }"
                :mini-icon="rp.icon" :text="rp.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

const { t } = useI18n();

const route = useRoute();

// const data = reactive({
//   user: "Name Lastname",
//   role: "Administrator",
// });


const data = reactive({
  user: "Name Lastname",
  role: "Administrator",
});


const loadDataList = async () => {
  if (localStorage.getItem("userName") !== null) {
    data.user = localStorage.getItem("userName");
    // console.log("user : ", data.user);
  }
};

loadDataList();

const account = reactive([
  // {
  //   path: "Profile Overview",
  //   icon: "face",
  //   name: computed(() => t("apps.acprofile")),
  // },
  // {
  //   path: "Settings",
  //   icon: "settings",
  //   name: computed(() => t("apps.acsetting")),
  // },
  {
    path: "Signin Basic",
    icon: "logout",
    name: computed(() => t("apps.aclogout")),
  },
]);

const dashboard = reactive([
  {
    path: "Dashboard",
    icon: "dashboard",
    name: computed(() => t("apps.gdashboard")),
  },
]);

const general = reactive([
  {
    path: "Transaction",
    icon: "chevron_right",
    name: computed(() => t("apps.gtransaction")),
  },
  {
    path: "CheckTicket",
    icon: "chevron_right",
    name: computed(() => t("apps.checktxn")),
  },
  {
    path: "History",
    icon: "chevron_right",
    name: computed(() => t("apps.ghistory")),
  },
  {
    path: "DeferItems",
    icon: "chevron_right",
    name: computed(() => t("apps.gdeferitems")),
  },
  {
    path: "Seize",
    icon: "chevron_right",
    name: computed(() => t("apps.seize")),
  },
  // {
  //   path: "UnitOverdue",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.gunitover")),
  // },
]);

const financial = reactive([
  {
    path: "Finance",
    icon: "chevron_right",
    name: computed(() => t("apps.gfinance")),
  },
  {
    path: "VerifyPayment",
    icon: "chevron_right",
    name: computed(() => t("apps.verifypayment")),
  },
  {
    path: "TransactionOverall",
    icon: "chevron_right",
    name: computed(() => t("apps.goverall")),
  },
  {
    path: "CashMovement",
    icon: "chevron_right",
    name: computed(() => t("apps.gcashmove")),
  },
  {
    path: "TranctionsManagement",
    icon: "chevron_right",
    name: computed(() => t("apps.gtrans")),
  },
  {
    path: "CashDrawer",
    icon: "chevron_right",
    name: computed(() => t("apps.closecashdrawer")),
  },
  {
    path: "BankDrawer",
    icon: "chevron_right",
    name: computed(() => t("apps.closebankdrawer")),
  },
  {
    path: "Reconcile",
    icon: "chevron_right",
    name: computed(() => t("apps.reconcile")),
  },

]);

const customer = reactive([
  {
    path: "CustomerInfo",
    icon: "chevron_right",
    name: computed(() => t("apps.gcustomer")),
  },
  // {
  //   path: "CustomerPort",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.gport")),
  // },
  // {
  //   path: "CustomerDeposit",
  //   icon: "chevron_right",
  //   name: computed(() => t("apps.gdeposit")),
  // },
]);

const stock = reactive([
  {
    path: "Stock",
    icon: "chevron_right",
    name: computed(() => t("apps.stock")),
  },
]);

// const report = reactive([
//   {
//     path: "ReportAll",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportall")),
//   },
//   {
//     path: "ReportDay",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportday")),
//   },
//   {
//     path: "ReportPawn",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportpawn")),
//   },
//   {
//     path: "ReportpProfit",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportprofit")),
//   },
//   {
//     path: "ReportAddCap",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportaddcap")),
//   },
//   {
//     path: "ReportReduceCap",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportreducecap")),
//   },
//   {
//     path: "ReportRedeem",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportredeem")),
//   },
//   {
//     path: "ReportCancel",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportcancel")),
//   },
//   {
//     path: "ReportDefer",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportdefer")),
//   },
//   {
//     path: "ReportErrorTicket",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reporterrorticket")),
//   },
//   {
//     path: "ReportTop20",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reporttop")),
//   },
//   {
//     path: "ReportTransaction",
//     icon: "chevron_right",
//     name: computed(() => t("apps.reportpay")),
//   },
//   {
//     path: "ExportOverdueItems",
//     icon: "chevron_right",
//     name: computed(() => t("apps.exportitemoverdue")),
//   },
// ]);


function getRoute() {
  const routeArr = route.path.split("/");
  return routeArr[1];
}
</script>
